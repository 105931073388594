import React, { useContext, useEffect, useState } from "react";

import {
	CommandBar,
	IBreadcrumbItem,
	ICommandBarItemProps,
	IconButton,
	IStackTokens,
	PrimaryButton,
	Stack,
	Text,
} from "@fluentui/react";
import { baseUrl } from "api/config";
import { AuthStateContext, UserType } from "authentication";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ErrorDialog, Loading } from "shared/components";
import { useImmer } from "use-immer";
import { IQuestionDetailsProps, ISectionQuery, ITemplate, useSection } from "..";
import { useStyles } from "./QuestionDetails.styles";
import { SectionList } from "admin/questionare";
import { useError } from "shared/hooks";
import { ToastContainer, toast } from "react-toastify";
import {
	Breadcrumb,
	BreadcrumbItem,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Toast,
	ToastBody,
	ToastHeader,
	Tooltip,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { Input } from "../../../../client/components/Controls/Input";
import Axios from "axios";
import { FormConfig } from "./FormConfig";
export const QuestionDetails: React.FC<IQuestionDetailsProps> = (props) => {
	const axios = require("axios");
	const { onEditForm, goToForms } = props;
	const classes = useStyles();
	const queryString = require("query-string");
	const history = useHistory();
	const match = useRouteMatch();
	const location = useLocation();
	const { errorstate, onErrorClose, onError } = useError();
	const sectionQuery = queryString.parse(location.search) as ISectionQuery;
	const { state } = useSection(sectionQuery, onError);
	const { sections, isBusy, query } = state;
	const authState = useContext(AuthStateContext);
	const [isLoading, setIsLoading] = useImmer<boolean>(false);
	const [templates, setTemplates] = useImmer<ITemplate[]>([]);
	const fileInputRef = React.useRef<HTMLInputElement>(null);
	const [modal, setModal] = useState(false);
	const { user } = authState;
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [formData, setFormData] = useImmer({
		title: "",
	});
	const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
	// let initial = forms.forms;
	// let formId = sectionQuery.formId ?? 0;
	// const formInfo = initial.filter((c) => c.formId === +formId)[0];
	// const currentConfig = () => {
	// 	if (authState.user.userType === UserType.Admin) {
	// 		return formInfo.calcConfig[0];
	// 	} else {
	// 		let config = formInfo.calcConfig.filter(
	// 			(c) => c.userId === authState.user.userId.toString()
	// 		);

	// 		if (config.length > 0) {
	// 			return config[0];
	// 		} else {
	// 			return formInfo.calcConfig[0];
	// 		}
	// 	}
	// };
	const initial = {
		userId: authState.user.userType === UserType.Admin ? 0 : authState.user.userId,
		formula: {
			yearlyInt: 0,
			taxPc: 0,
			marketingCost: 0,
			referralConversionRate: 0,
			conversionRateIncrease: 0,
			productIncrease: 0,
			salesIncrease: 0,
			cosReduce: 0,
			bankFeesSavingRate: 0,
			insuranceSavingRate: 0,
			merchantFeesSavingRate: 0,
			telephoneCostsSavingRate: 0,
			printingCostsSavingRate: 0,
			rentSavingRate: 0,
			utilitiesSavingRate: 0,
			bottleNeckReductionRate: 0,
			professionalFeesReductionRate: 0,
			vehicleExpensesReductionRate: 0,
			salesBaseIncreaseRate: 0,
			overStockSavingRate: 0,
			overstaffingRate: 0,
		},
	};

	const [formInfo, setFormInfo] = useImmer(initial as any);
	useEffect(() => {
		Axios.get(baseUrl + `form/config/${sectionQuery.formId}/${initial.userId}`).then((res) => {
			if (!res.data.formula) {
				setFormInfo((draft) => {
					return initial;
				});
			} else {
				setFormInfo((draft) => {
					return res.data;
				});
			}
		});
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		console.log("test");

		if (sections.length) {
			setFormData((draft) => {
				draft.title = sections[0].formName;
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sections]);

	const toggle = () => {
		setModal(!modal);
	};
	const onSubmit = () => {
		Axios.post(baseUrl + `form/config/${sectionQuery.formId}`, formInfo).then((res) => {
			if (!res.data.formula) {
				setFormInfo((draft) => {
					return initial;
				});
			} else {
				setFormInfo((draft) => {
					return res.data;
				});
			}
		});
		setModal(!modal);
	};
	const onChange = (event: any) => {
		const { name, value } = event.target;
		const reg = /^(\d{0,2})?(\.\d{0,3})?$/;
		if (reg.test(value)) {
			setFormInfo((draft) => {
				draft.formula[name] = +value;
				return draft;
			});
		}
	};

	const titleChange = (event: any) => {
		const { value } = event.target;
		setFormData((draft) => {
			draft.title = value;
			return draft;
		});
	};

	const updateTitle = () => {
		Axios.put(baseUrl + `form/UpdateTitle`, {
			FormId: sectionQuery.formId,
			Title: formData.title,
		})
			.then((res) => {
				if (res.status === 200) {
					toast("BAQ Title has been updated!");
				}
			})
			.catch((err) => console.log(err));
	};

	const verticalGapStackTokens: IStackTokens = {
		childrenGap: 15,
		padding: 10,
	};
	const getReportTemplates = (formId: number | undefined) => {
		try {
			setIsLoading((draft) => {
				draft = true;
			});
			axios
				.get(baseUrl + "Report/Templates/" + formId)
				.then((res: any) => {
					const reportTemplates: ITemplate[] = res.data;
					setTemplates((draft) => {
						draft = reportTemplates;
						return draft;
					});
					setIsLoading((draft) => {
						draft = false;
						return draft;
					});
				})
				.catch((err: any) => {
					onError(err.response.data);
				});
		} catch (e) {
			onError(e.message);
		}
	};

	React.useEffect(() => {
		if (sectionQuery.formId) {
			getReportTemplates(sectionQuery.formId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isBusy) {
		return <Loading />;
	}

	if (isLoading) {
		return <Loading />;
	}

	const copyForm = () => {
		setIsLoading((draft) => {
			draft = true;
			return draft;
		});
		axios
			.post(baseUrl + "Report/Duplicate/" + sectionQuery.formId)
			.then((res: any) => {
				goToForms();
				setIsLoading((draft) => {
					draft = false;
					return draft;
				});
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	};

	const items: ICommandBarItemProps[] = [
		{
			key: "logo",
			onRender: (item) => (
				<Stack style={{ padding: "13px" }}>
					<b>Section Details</b>
				</Stack>
			),
		},
	];

	const getQuestionCount = (): number => {
		let num = 0;
		sections.forEach((s) => {
			num = num + s.totalQuestions;
		});
		return num;
	};

	const _farItems: ICommandBarItemProps[] = [
		{
			key: "count",
			onRender: (item) => (
				<Stack style={{ padding: "13px" }}>
					<b>{getQuestionCount()} Questions</b>
				</Stack>
			),
		},
		{
			key: "Copy",
			text: "Copy",
			ariaLabel: "Copy",
			iconOnly: false,
			iconProps: { iconName: "Copy" },
			onClick: () => copyForm(),
		},
		{
			key: "tile",
			text: "Edit",
			ariaLabel: "Edit",
			iconOnly: false,
			iconProps: { iconName: "WindowEdit" },
			onClick: () => {
				onEditForm(query.formId);
			},
		},
		{
			key: "info",
			text: "Delete",
			ariaLabel: "Delete",
			iconOnly: false,
			iconProps: { iconName: "Delete" },
			onClick: () => console.log("Info"),
		},
	];
	if (authState.user.userType === UserType.BIP) _farItems.splice(0);

	const handleUploadButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const onUploadReportTemplate = (files: File[], formId: number | undefined) => {
		setIsLoading((draft) => {
			draft = true;
			return draft;
		});
		const form = new FormData();
		if (formId) {
			form.set("FormId", formId.toString());
		}
		files.forEach((s) => {
			form.append("files", s);
		});

		axios({
			method: "post",
			url: baseUrl + "Report/UploadTemplate",
			data: form,
			headers: {
				"content-type": `multipart/form-data;`,
			},
		})
			.then((res: any) => {
				getReportTemplates(formId);
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	};

	const handleFileChanged = () => {
		const fileInput = fileInputRef.current;
		const files = fileInput?.files;
		if (files && files.length > 0) {
			const arrFiles = Array.from(files);
			onUploadReportTemplate(arrFiles, query.formId);
		}
	};

	const itemsWithHref: IBreadcrumbItem[] = [
		{ text: "BAQ", key: "f3", onClick: () => goToForms() },
	];

	if (sections.length > 0) {
		itemsWithHref.push({ text: sections[0].formName, key: "f3" });
	}

	const deleteTemplate = (templateId: number) => {
		axios
			.delete(baseUrl + "Report/Templates/" + templateId)
			.then((res: any) => {
				getReportTemplates(sectionQuery.formId);
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	};

	const publishBAQ = () => {
		Axios.post(baseUrl + `Form/Publish/${sectionQuery.formId}`)
			.then((res) => {
				history.goBack();
			})
			.catch((err) => console.log(err));
	};

	return (
		<>
			<ToastContainer></ToastContainer>
			<Breadcrumb tag="nav" listTag="div">
				<BreadcrumbItem
					tag={Link}
					to={`/${user.userType === UserType.BIP ? "bip" : "admin"}/home`}
				>
					Dashboard
				</BreadcrumbItem>
				<BreadcrumbItem
					tag={Link}
					to={`/${user.userType === UserType.BIP ? "bip" : "admin"}/questionare`}
				>
					BAQ
				</BreadcrumbItem>
				<BreadcrumbItem active>{sections && sections[0].formName}</BreadcrumbItem>
			</Breadcrumb>
			<div className="row">
				<div className="col-md-8">
					<div className="row mb-2">
						<div className="col-md-12">
							<div className="card">
								<div className="card-body">
									<div className="row">
										<div className="col-md-10">
											<input
												className="form-control"
												onChange={titleChange}
												disabled={user.userType !== UserType.Admin}
												value={formData.title}
											></input>
										</div>
										<div className="col-md-2">
											{user.userType === UserType.Admin && (
												<button
													className="btn btn-outline-primary"
													onClick={updateTitle}
												>
													Update
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-header bg-white">
							<div className="float-left mt-2">
								Status :{" "}
								{sections && sections[0].formStatus === 0 ? "Draft" : "Published"}
							</div>

							<div className="float-right">
								<button className="btn btn-outline-dark ml-2" onClick={toggle}>
									<i className="fas fa-cog"></i> Calculation Settings
								</button>
								<span>
									<button
										className="btn btn-outline-primary ml-2"
										onClick={copyForm}
										id={"Tooltip-baq"}
									>
										<i className="fas fa-copy"></i> Duplicate
									</button>
									<Tooltip
										placement={"right"}
										isOpen={tooltipOpen}
										target={"Tooltip-baq"}
										toggle={toggleTooltip}
									>
										This will create a copy of BAQ
									</Tooltip>
								</span>

								{sections && sections[0].formStatus === 0 && (
									<>
										<button
											className="btn btn-outline-primary ml-2"
											onClick={() => onEditForm(query.formId)}
										>
											<i className="fas fa-edit"></i> Edit
										</button>
										<button
											className="btn btn-outline-success ml-2"
											onClick={publishBAQ}
										>
											<i className="fas fa-paper-plane"></i> Publish
										</button>
									</>
								)}

								{/* <button className="btn btn-outline-danger ml-2">
									<i className="fas fa-trash"></i> Edit
								</button> */}
							</div>
						</div>
						<table className="table">
							<thead className="thead-light">
								<tr>
									<th>#</th>
									<th>Title</th>
									<th>Questions</th>
								</tr>
							</thead>
							<tbody>
								{sections.map((item, index) => {
									return (
										<tr>
											<td>{index + 1}</td>
											<td>{`${item.name}`}</td>
											<td>{item.totalQuestions}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				<div className="col-md-4">
					<div className="card">
						<div className="card-header bg-white">Report Templates</div>
						<div className="card-body">
							{templates.map((report: ITemplate, index: number) => {
								return (
									<div className="row">
										<div className="col-md-2">
											<img src="/word_icon.png" alt="icon" />
										</div>
										<div className="col-md-8">
											{report.fileName} <div>{report.uploadedUTC}</div>
										</div>
										<div className="col-md-2">
											<button
												className="btn btn-sm btn-outline-danger"
												onClick={() => deleteTemplate(report.templateId)}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</div>
									</div>
								);
							})}
						</div>
						<div className="row">
							<div className="col-md-4 ml-4 p-2">
								<input
									name="img"
									type="file"
									accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									ref={fileInputRef}
									onChange={handleFileChanged}
									style={{ display: "none" }}
								/>
								<button
									className="btn btn-primary"
									onClick={handleUploadButtonClick}
								>
									<i className="fas fa-upload mr-2"></i>
									Upload
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modal && (
				<FormConfig
					onCancel={toggle}
					onSubmit={onSubmit}
					config={formInfo}
					onChange={onChange}
				/>
			)}
		</>
	);

	return (
		<>
			<Stack>
				<Stack.Item grow={10} align="start">
					<Breadcrumb
						items={itemsWithHref}
						ariaLabel="Breadcrumb with items rendered as links"
						overflowAriaLabel="More links"
					/>
				</Stack.Item>
			</Stack>
			<Stack>
				<ErrorDialog
					errorMessage={errorstate.errorMessage}
					show={errorstate.visible}
					onClose={onErrorClose}
				/>
			</Stack>
			<Stack horizontal tokens={verticalGapStackTokens}>
				<Stack.Item grow={8}>
					<CommandBar
						className={classes.commandBar}
						items={items}
						overflowItems={[]}
						farItems={_farItems}
					/>
					<SectionList
						basePath={match.path}
						sections={sections}
						sectionQuery={sectionQuery}
					/>
					{/* <Stack.Item>
						<CommandBar
							className={classes.commandBar}
							items={[]}
							overflowItems={[]}
							farItems={_farItems}
						/>
						<SectionList
							basePath={match.path}
							sections={sections}
							sectionQuery={sectionQuery}
						/>
					</Stack.Item> */}
				</Stack.Item>
				<Stack.Item grow={4}>
					<Stack className={classes.cardMain}>
						<Stack.Item className={classes.cardMainHeading}>
							<Stack horizontal={true}>
								<Stack.Item>
									<Text variant={"xLarge"}>BA Report Template</Text>
								</Stack.Item>
							</Stack>
						</Stack.Item>
						<Stack className={classes.cardContent}>
							<Stack>
								<Stack.Item align="center">
									{templates.map((report: ITemplate, index: number) => {
										return (
											<Stack horizontal style={{ padding: 5 }}>
												<Stack.Item>
													<img src="/word_icon.png" alt="icon" />
												</Stack.Item>
												<Stack.Item>
													{report.fileName}{" "}
													<div>{report.uploadedUTC}</div>
												</Stack.Item>
												<Stack.Item>
													<IconButton
														iconProps={{ iconName: "ChromeClose" }}
														title="Emoji"
														ariaLabel="Emoji"
														onClick={() =>
															deleteTemplate(report.templateId)
														}
													/>
												</Stack.Item>
											</Stack>
										);
									})}
								</Stack.Item>

								<Stack.Item align="center">
									<input
										name="img"
										type="file"
										accept=".docx | .doc"
										ref={fileInputRef}
										onChange={handleFileChanged}
										style={{ display: "none" }}
									/>
									<PrimaryButton
										type="file"
										onClick={handleUploadButtonClick}
										text="UPLOAD"
									/>
								</Stack.Item>
							</Stack>
						</Stack>
					</Stack>
				</Stack.Item>
				{/* <Stack.Item grow={1}>
					<CommandBar
						className={classes.commandBar}
						items={[]}
						overflowItems={[]}
						farItems={_farItems}
					/>
					<SectionList
						basePath={match.path}
						sections={sections}
						sectionQuery={sectionQuery}
					/>
				</Stack.Item>
				<Stack.Item grow={2} className={classes.cardMain}>
					<Stack.Item className={classes.cardMainHeading}>
						<Stack horizontal={true}>
							<Stack.Item grow={12}>
								<Text variant={"xLarge"}>BA Report</Text>
							</Stack.Item>
						</Stack>
					</Stack.Item>
					<Stack className={classes.cardContent}>
						<Stack.Item align="center">
							<p style={{ fontSize: 22, textAlign: "center" }}></p>
						</Stack.Item>
						<Stack.Item align="center">
							<PrimaryButton>UPLOAD</PrimaryButton>
						</Stack.Item>
					</Stack>
				</Stack.Item> */}
			</Stack>
		</>
	);
};
