import React, { useContext, useState } from "react";

import { Stack } from "@fluentui/react";
import ProtectedRoute from "authentication/ProtectedRoute";
import { AuthStateContext } from "authentication/useAuthContext";
import { Header } from "client/components";
import { AnswerContainer, ClientHome, EditProfile } from "client/containers";
import { Link, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { ClientSignIn, IClientDashBoardprops } from ".";
import { useStyles } from "./ClientDashBoard.styles";
import { LogOut, UserAuthDispatcher } from "authentication";

export const ClientDashBoard: React.FC<IClientDashBoardprops> = (props) => {
	const match = useRouteMatch();
	const classes = useStyles();
	const history = useHistory();
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const [toggle, setToggle] = useState(false);
	const userAuthDispatcher = UserAuthDispatcher();

	const goToAnswer = (formId: number) => {
		history.push(`${match.path}/answer?form=${formId}&client=${user.userId}`);
	};

	const onEditProfile = () => {
		history.push(`${match.path}/editprofile?userId=${user.userId}`);
	};

	const ToggleMenu = () => {
		setToggle(!toggle);
	};
	const getShortName = () => {
		return `${user.firstName?.charAt(0).toString()}`;
	};

	return (
		<section className="main-container">
			<nav className="navbar navbar-expand-lg navbar-light">
				<Link className="navbar-brand" to="/client/home">
					<img src="/images/profitune-logo.svg" alt="Profitune Logo" />
				</Link>
				<div className="dropdown" onClick={ToggleMenu}>
					<a className="btn btn-secondary-c dropdown-toggle" href="#" role="button">
						<figure className="user">{getShortName()}</figure> {user.firstName}
					</a>
					<div
						className={"dropdown-menu " + (toggle && "show")}
						aria-labelledby="dropdownMenuLink"
					>
						<button className="dropdown-item" onClick={onEditProfile}>
							<i className="icon-iregistration" />
							Edit Profile
						</button>
						<button
							className="dropdown-item"
							onClick={() => LogOut(userAuthDispatcher)}
						>
							<i className="icon-shutdown" />
							Logout
						</button>
					</div>
				</div>
			</nav>
			{/* <div className="container-wrapper">
				<div className="container-fluid mt-5">
					
				</div>
			</div> */}
			<Switch>
				<ProtectedRoute
					exact={true}
					path={`${match.path}/home`}
					render={() => {
						return <ClientHome goToAnswer={goToAnswer} />;
					}}
				/>
				<ProtectedRoute
					path={`${match.path}/editprofile`}
					render={() => {
						return <EditProfile />;
					}}
				/>
				<ProtectedRoute
					path={`${match.path}/answer`}
					render={() => {
						return <AnswerContainer AnswerViewType={"Answer"} />;
					}}
				/>
				<Route exact path={"/signin"}>
					<ClientSignIn />
				</Route>
			</Switch>
		</section>
	);
};
