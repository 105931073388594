import {
	ChoiceGroup,
	Dropdown,
	IBreadcrumbItem,
	IChoiceGroupOption,
	IComboBoxOption,
	IDropdownOption,
	IStackTokens,
	PrimaryButton,
	Stack,
	TextField,
} from "@fluentui/react";
import { UserType } from "authentication/auth.types";
import { useBipUser } from "admin/bipuser";
import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ErrorDialog, Loading } from "shared/components";
import { IClientQuery, ICreateClientProps, useClient } from ".";
import { useStyles } from "./CreateClient.styles";
import { useError } from "shared/hooks";
import Axios from "axios";
import { baseUrl } from "api/config";
import { useImmer } from "use-immer";
import { ICountry } from "./useClient.types";
import { Breadcrumb, BreadcrumbItem, Card } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { Input } from "client/components/Controls/Input";
import { AuthStateContext } from "authentication";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 15,
	padding: 10,
};

const horizontalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 5,
};

export const CreateClient: React.FC<ICreateClientProps> = (props) => {
	const { mode, onClientNavigateBack, gotoDetails } = props;
	const queryString = require("query-string");
	const location = useLocation();
	const classes = useStyles();
	const clientUserQuery = queryString.parse(location.search) as IClientQuery;

	const { errorstate, onErrorClose, onError } = useError();
	const { state, onCreate, onSave, onChange } = useClient(mode, clientUserQuery, onError);
	const bipUser = useBipUser("LIST", { bipId: undefined }, onError);
	const [countryList, setCountryList] = useImmer<ICountry[]>([]);
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const { selectedClient, roleId, userType } = state;
	const {
		emailAddress,
		firstName,
		lastName,
		clientId,
		currency,
		businessName,
		addressLine1,
		addressLine2,
		businessId,
		city,
		countryId,
		street,
		zip,
		bipid,
		mobile,
		officePhone,
		userStatus,
	} = selectedClient;

	useEffect(() => {
		Axios.get(baseUrl + "common/country").then((res) => {
			setCountryList((draft) => {
				return res.data;
			});
		});
	}, [setCountryList]);

	if (state.isBusy) {
		return <Loading />;
	}

	const options: IChoiceGroupOption[] = [
		{ key: "A", text: "Active" },
		{ key: "B", text: "InActive" },
	];
	const currencyList: IComboBoxOption[] = [
		{ key: "$", text: "AUD" },
		{ key: "$", text: "USD" },
		{ key: "€", text: "EURO" },
		{ key: "£", text: "POUND" },
	];
	const itemsWithHref: IBreadcrumbItem[] = [
		{ text: "clients", key: "f3", onClick: onClientNavigateBack },
	];

	if (mode === "CREATE") itemsWithHref.push({ text: "Create Client", key: "f4" });
	if (mode === "EDIT" && gotoDetails) {
		if (clientId && firstName && lastName) {
			itemsWithHref.push({
				text: firstName + " " + lastName,
				key: "f3",
				//	href: "/admin/client/details?clientId=" + clientId,
				onClick: () => gotoDetails(clientUserQuery.clientId),
			});
			itemsWithHref.push({ text: "Edit Client", key: "f4" });
		}
	}
	const onFirstNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("FirstName", newValue);
	};

	const onLastNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("LastName", newValue);
	};

	const onEmailChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("Email", newValue);
	};

	const onBusinessNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("BusinessName", newValue);
	};

	const onAddressLine1Change = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("AddressLine1", newValue);
	};

	const onAddressLine2Change = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("AddressLine2", newValue);
	};
	const onBusinessIdChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("BusinessId", newValue);
	};
	const onCityChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("City", newValue);
	};
	const onStreetChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("Street", newValue);
	};

	const onMobileChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("Mobile", newValue);
	};

	const onOfficePhoneChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("OfficePhone", newValue);
	};

	const onZipChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("Zip", newValue);
	};

	const onComboChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onChange && onChange("currency", event.target.value);
	};

	const onCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onChange && onChange("CountryId", event.target.value);
	};

	const onBIPChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onChange && onChange("BIP", event.target.value);
	};

	const onChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange && onChange("UserStatus", event.target.checked ? "1" : "0");
	};

	const loadBIPUsers = (): IDropdownOption[] => {
		let userList: IDropdownOption[] = [];

		bipUser.state.users.forEach((user) => {
			userList.push({
				key: user.bipId,
				text: `${user.firstName} ${user.lastName}`,
			});
		});

		return userList;
	};

	const loadCountries = (): IDropdownOption[] => {
		let countries: IDropdownOption[] = [];
		countryList.forEach((country) => {
			countries.push({
				key: country.countryId,
				text: `${country.name}`,
			});
		});
		return countries;
	};

	return (
		<>
			<ErrorDialog
				errorMessage={errorstate.errorMessage}
				show={errorstate.visible}
				onClose={onErrorClose}
			/>
			<Breadcrumb tag="nav" listTag="div">
				<BreadcrumbItem
					tag={Link}
					to={`/${user.userType === UserType.BIP ? "bip" : "admin"}/home`}
				>
					Dashboard
				</BreadcrumbItem>
				<BreadcrumbItem
					tag={Link}
					to={`/${user.userType === UserType.BIP ? "bip" : "admin"}/client`}
				>
					Client
				</BreadcrumbItem>
				<BreadcrumbItem active>
					{mode === "CREATE" ? "New Client" : "Edit Client"}
				</BreadcrumbItem>
			</Breadcrumb>
			<AvForm onValidSubmit={mode === "CREATE" ? onCreate : onSave}>
				<div className="col-md-8">
					<Card>
						<div className="card-body">
							<div className="form-wrap">
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="First Name"
											name="First Name"
											value={firstName}
											onChange={onFirstNameChange}
											type="text"
											required
										/>
									</div>
									<div className="col-sm-6">
										<Input
											label="Last Name"
											name="LastName"
											value={lastName}
											onChange={onLastNameChange}
											type="text"
											required
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="Email"
											name="Email"
											helpMessage="Password will be send to this email."
											value={emailAddress}
											type="email"
											onChange={onEmailChange}
											required
										/>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>Currency</label>
											<Input
												name="currency"
												type="select"
												value={currency}
												onChange={onComboChange}
											>
												<option value="">Choose</option>
												<option value="$">AUD</option>
												<option value="$">USD</option>
												<option value="€">EURO</option>
												<option value="£">POUND</option>
											</Input>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="Business Id"
											name="businessId"
											value={businessId}
											type="Text"
											onChange={onBusinessIdChange}
											required
										/>
									</div>
									<div className="col-sm-6">
										<Input
											label="Business Name"
											name="businessName"
											value={businessName}
											type="Text"
											onChange={onBusinessNameChange}
											required
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="Address Line 1"
											name="addressLine1"
											value={addressLine1}
											type="Text"
											onChange={onAddressLine1Change}
											required
										/>
									</div>
									<div className="col-sm-6">
										<Input
											label="Address Line 2"
											name="addressLine2"
											value={addressLine2}
											type="Text"
											onChange={onAddressLine2Change}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="City"
											name="city"
											value={city}
											type="Text"
											onChange={onCityChange}
											required
										/>
									</div>
									<div className="col-sm-6">
										<Input
											label="Street"
											name="Street"
											value={street}
											type="Text"
											onChange={onStreetChange}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="Post Code"
											name="zip"
											value={zip}
											type="Text"
											onChange={onZipChange}
											required
										/>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>Country</label>
											<Input
												name="country"
												id="country"
												type="select"
												value={countryId}
												onChange={onCountryChange}
											>
												<option style={{ display: "none" }}></option>
												{countryList.map(
													(country: ICountry, index: number) => {
														return (
															<option value={country.countryId}>
																{country.name}
															</option>
														);
													}
												)}
											</Input>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="Mobile"
											name="mobile"
											value={mobile}
											type="Number"
											onChange={onMobileChange}
											required
										/>
									</div>
									<div className="col-sm-6">
										<Input
											label="Office Phone"
											name="officePhone"
											value={officePhone}
											type="Number"
											onChange={onOfficePhoneChange}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label>BIP</label>
											<Input
												name="bip"
												id="bip"
												type="select"
												value={bipid}
												disabled={userType === UserType.BIP}
												onChange={onBIPChange}
											>
												<option style={{ display: "none" }}></option>
												{bipUser.state.users.map((item) => {
													return (
														<option value={item.bipId}>
															{item.firstName} {item.lastName}
														</option>
													);
												})}
											</Input>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<div className="pt-checkbox">
												<input
													id={`status`}
													type="Checkbox"
													checked={userStatus === 0 ? false : true}
													onChange={onChangeStatus}
												/>
												<label className="pt-checkbox" htmlFor={`status`}>
													Is Active
												</label>
											</div>
										</div>
									</div>
								</div>
								<button className="btn btn-primary" type="submit">
									Save
								</button>
							</div>
						</div>
					</Card>
				</div>
			</AvForm>
		</>
	);

	// return (
	// 	<Stack tokens={verticalGapStackTokens} className={classes.root}>
	// 		<Stack.Item>
	// 			<Breadcrumb
	// 				items={itemsWithHref}
	// 				maxDisplayedItems={3}
	// 				ariaLabel="Breadcrumb with items rendered as links"
	// 				overflowAriaLabel="More links"
	// 			/>
	// 		</Stack.Item>
	// 		<Stack>
	// 			<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 		</Stack>
	// 		<Stack className={classes.form}>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<TextField
	// 						label="First Name"
	// 						value={firstName}
	// 						onChange={onFirstNameChange}
	// 					/>
	// 				</Stack.Item>
	// 				<Stack.Item grow={6}>
	// 					<TextField label="Last Name" value={lastName} onChange={onLastNameChange} />
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<TextField label="Email" value={emailAddress} onChange={onEmailChange} />
	// 				</Stack.Item>
	// 				<Stack.Item grow={6}>
	// 					<Dropdown
	// 						selectedKey={currency}
	// 						label="Currency"
	// 						onChange={onComboChange}
	// 						options={currencyList}
	// 					/>
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<TextField
	// 						label="Business Name"
	// 						value={businessName}
	// 						onChange={onBusinessNameChange}
	// 					/>
	// 				</Stack.Item>
	// 				<Stack.Item grow={6}>
	// 					<Dropdown
	// 						disabled={roleId === UserType.BIP}
	// 						selectedKey={bipid}
	// 						label="BIP"
	// 						onChange={onBIPChange}
	// 						options={loadBIPUsers()}
	// 					/>
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<TextField
	// 						label="Address Line 1"
	// 						value={addressLine1}
	// 						onChange={onAddressLine1Change}
	// 					/>
	// 				</Stack.Item>
	// 				<Stack.Item grow={6}>
	// 					<TextField
	// 						label="Address line 2"
	// 						value={addressLine2}
	// 						onChange={onAddressLine2Change}
	// 					/>
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<TextField label="City" value={city} onChange={onCityChange} />
	// 				</Stack.Item>
	// 				<Stack.Item grow={6}>
	// 					<TextField label="Street" value={street} onChange={onStreetChange} />
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<TextField label="Zip" value={zip} onChange={onZipChange} />
	// 				</Stack.Item>
	// 				<Stack.Item grow={6}>
	// 					<Dropdown
	// 						selectedKey={countryId}
	// 						label="Country"
	// 						onChange={onCountryChange}
	// 						options={loadCountries()}
	// 					/>
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<TextField label="Mobile" value={mobile} onChange={onMobileChange} />
	// 				</Stack.Item>
	// 				<Stack.Item grow={6}>
	// 					<TextField
	// 						label="Office Phone"
	// 						value={officePhone}
	// 						onChange={onOfficePhoneChange}
	// 					/>
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack.Item tokens={horizontalGapStackTokens}>
	// 				<ChoiceGroup
	// 					defaultSelectedKey="A"
	// 					options={options}
	// 					//onChange={_onChange}
	// 					label="Account Status"
	// 					required={true}
	// 				/>
	// 			</Stack.Item>
	// 			<Stack.Item>
	// 				{mode === "CREATE" ? (
	// 					<PrimaryButton text={"SAVE"} onClick={onCreate} />
	// 				) : (
	// 					<PrimaryButton text={"SAVE"} onClick={onSave} />
	// 				)}
	// 			</Stack.Item>
	// 		</Stack>
	// 	</Stack>
	// );
};
