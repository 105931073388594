import {
	IPersonaSharedProps,
	IStackTokens,
	Persona,
	PersonaInitialsColor,
	PersonaSize,
	PrimaryButton,
	Stack,
	Text,
} from "@fluentui/react";
import { IClient } from "admin/client";
import { IFormSummary } from "admin/questionare";
import { baseUrl } from "api/config";
import { AuthStateContext } from "authentication/useAuthContext";
import Axios from "axios";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Spinner } from "reactstrap";
import { ErrorDialog } from "shared/components";
import { useError } from "shared/hooks";
import { useImmer } from "use-immer";
import { IErrors, useAnswer } from "..";
import { useStyles } from "./ClientHome.styles";
import { IClientHomeProps } from "./ClientHome.types";

const sectionStackTokens: IStackTokens = { childrenGap: 20 };
const verticalStackTokens: IStackTokens = { childrenGap: 8, padding: 10 };
const horizontalGapStackTokens: IStackTokens = {
	childrenGap: 25,
};

export const ClientHome: React.FC<IClientHomeProps> = (props) => {
	const { goToAnswer } = props;
	const classes = useStyles();
	const [isBusy, setIsBusy] = useState(false);
	const [baq, setBaq] = useImmer<IFormSummary[]>([]);
	const [errors, setErrors] = useImmer<IErrors>({ errors: [] });
	const [client, setClient] = useImmer<IClient>({
		bipid: 0,
		businessName: "",
		clientId: 0,
		currency: "",
		emailAddress: "",
		firstName: "",
		lastName: "",
		addressLine1: "",
		addressLine2: "",
		businessId: "",
		city: "",
		countryId: null,
		street: "",
		zip: "",
		mobile: "",
		officePhone: "",
		userStatus: 0,
		baqStatus: "",
	});
	const { errorstate, onErrorClose, onError } = useError();
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const { emailAddress, firstName, lastName, businessName, mobile } = client;
	const examplePersona: IPersonaSharedProps = {
		secondaryText: `${emailAddress}`,
		tertiaryText: `${businessName}`,
		optionalText: "",
	};
	const personaWithInitials: IPersonaSharedProps = {
		...examplePersona,
		text: ``,
	};

	useEffect(() => {
		if (client.clientId === 0) {
			setIsBusy(true);
			Axios.get(baseUrl + `Client/${user.userId}`)
				.then((res) => {
					const client: IClient = res.data;
					setClient((draft) => {
						draft = client;
						return draft;
					});
					setIsBusy(false);
				})
				.catch((err) => {
					setIsBusy(false);
					onError(err.response.data);
				});
		}
	}, [client, onError, setClient, user.userId]);

	useEffect(() => {
		Axios.get(baseUrl + `Client/GetBAQ/${user.userId}`)
			.then((res) => {
				setBaq(() => {
					return res.data;
				});
				setIsBusy(false);
			})
			.catch((err) => {
				setIsBusy(false);
				onError(err.response.data);
			});
	}, [onError, setBaq, user.userId]);

	const validateAndSubmit = (baqId: number) => {
		try {
			setIsBusy(true);
			Axios.get(baseUrl + "Form/ValidateBAQ?ClientId=" + user.userId + "&FormId=" + baqId)
				.then((res: any) => {
					const errors: IErrors = res.data;
					if (errors.errors.length > 0) {
						setIsBusy(false);
						toast.error(`Please answer all required questions!`);
						setErrors((draft) => {
							draft = errors;
						});
					} else {
						Axios.post(baseUrl + "Form/SubmitBAQ/" + user.userId + "/" + baqId)
							.then((res: any) => {
								setIsBusy(false);
								toast(`Success`);
								setBaq((draft) => {
									draft[0].isSubmited = true;
									return draft;
								});
							})
							.catch((err: any) => {
								setIsBusy(false);
								onError(err.response.data);
							});
					}
				})
				.catch((err: any) => {
					setIsBusy(false);
					onError(err.response.data);
				});
		} catch (e) {
			setIsBusy(false);
			onError(e.message);
		}
	};

	const onDownload = () => {
		Axios.get(baseUrl + `Report/Download/${baq[0].reportId}`, {
			responseType: "blob",
		})
			.then((res: any) => {
				let fileName = "";
				var header = res.headers["content-disposition"];
				var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				var matches = filenameRegex.exec(header);
				if (matches != null && matches[1]) {
					fileName = matches[1].replace(/['"]/g, "");
				}

				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	};
	const getShortName = () => {
		return `${firstName?.charAt(0).toString()}${lastName?.charAt(0).toString()}`;
	};
	const getCircle = (percent: number) => {
		var circumference = 100 * 2 * Math.PI;
		let currentStyle: CSSProperties = {};
		currentStyle.strokeDasharray = `${circumference} ${circumference}`;
		currentStyle.strokeDashoffset = circumference - (percent / 100) * circumference;
		return currentStyle;
	};

	return (
		<>
			<ToastContainer></ToastContainer>
			<div className="container-wrapper">
				<div className="container">
					<div>
						<ErrorDialog
							errorMessage={errorstate.errorMessage}
							show={errorstate.visible}
							onClose={onErrorClose}
						/>
					</div>
					{isBusy ? (
						<div className="d-flex justify-content-center align-items-center">
							<Spinner color="primary">Loading!</Spinner>
						</div>
					) : (
						<>
							<div className="item-block">
								<div className="item-padd">
									<div className="row">
										<div className="col-sm-6 col-lg-8">
											<div className="user-info">
												<figure className="user-pic">
													{getShortName()}
												</figure>
												<div className="user-details">
													<h2>
														{firstName} {lastName}
													</h2>
													<p>
														<span>{emailAddress}</span>
													</p>
													<p>{businessName}</p>
													<p>{mobile}</p>
												</div>
											</div>
										</div>
										<div className="col-sm-6 col-lg-4">
											<div className="qestionnaire-status">
												{baq.length > 0
													? baq[0].isSubmited
														? "Submitted"
														: baq[0].answered > 0
														? baq[0].answered === baq[0].totalQuestions
															? "Completed!"
															: "In Progress"
														: "BA Questionnaire yet to start"
													: "BAQ not assigned"}
											</div>
										</div>
									</div>
								</div>
							</div>
							{baq.length > 0 &&
								baq.map((item) => {
									let percentage = (item.answered / item.totalQuestions) * 100;
									return (
										<>
											<div className="item-block">
												<div className="item-head">
													<h3>BA Questionnaire</h3>
												</div>
												<div className="item-padd">
													<div className="row">
														<div
															key={item.formId}
															className="col-sm-12 col-lg-9 col-xl-8"
														>
															<div className="questionnaire-info">
																<div className="row">
																	<div className="col-md-4 mb-1">
																		<div className="status-block">
																			<h4>
																				{
																					item.totalQuestions
																				}
																			</h4>
																			<p>Total Questions</p>
																		</div>
																	</div>
																	<div className="col-md-4 mb-1">
																		<div className="status-block blue-block">
																			<h4>{item.answered}</h4>
																			<p>
																				Answered Questions
																			</p>
																		</div>
																	</div>
																	<div className="col-md-4 mb-1">
																		<div className="status-block yellow-block">
																			<h4>{item.pending}</h4>
																			<p>Pending Questions</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div
															key={`${item.formId}_1`}
															className="col-sm-12 col-lg-3 col-xl-4"
														>
															<div className="progress-ring-wrap">
																<svg
																	className="progress-ring"
																	width={222}
																	height={222}
																>
																	<circle
																		className="progress-ring__circle"
																		style={getCircle(
																			percentage
																		)}
																		stroke="#0132b7"
																		strokeWidth={10}
																		fill="#c4d4ff"
																		r={100}
																		cx={110}
																		cy={110}
																	/>
																</svg>
																<svg
																	className="progress-ring-inactive"
																	width={222}
																	height={222}
																>
																	<circle
																		className="progress-ring__circle"
																		stroke="#f4f4f4"
																		strokeWidth={0}
																		fill="#f4f4f4"
																		r={100}
																		cx={110}
																		cy={110}
																	/>
																</svg>
																<div className="progress-value">
																	<input
																		defaultValue={`${
																			percentage >= 100
																				? percentage
																				: percentage.toFixed(
																						2
																				  )
																		}%`}
																		type="text"
																		disabled
																		placeholder="0%"
																	/>
																	<p>Completed</p>
																</div>
															</div>
															{percentage === 100
																? !item.isSubmited &&
																  errors.errors.length === 0 && (
																		<button
																			className="btn-primary-c proceed-btn"
																			onClick={() => {
																				validateAndSubmit(
																					item.formId
																				);
																			}}
																		>
																			SUBMIT
																		</button>
																  )
																: !item.isSubmited && (
																		<button
																			className="btn-primary-c proceed-btn"
																			onClick={() =>
																				goToAnswer(
																					item.formId
																				)
																			}
																		>
																			{item.answered > 0
																				? "CONTINUE"
																				: "PROCEED TO ANSWER"}
																		</button>
																  )}
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							{baq.length > 0 && baq[0].reportId !== 0 && (
								<div className="item-block">
									<div className="item-head">
										<h3>BA Report</h3>
									</div>
									<div className="item-padd text-center">
										<p>Please download your Business Analysis Report</p>
										<button
											className="btn-primary-c proceed-btn"
											onClick={onDownload}
										>
											DOWNLOAD REPORT
										</button>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);

	// return (
	// 	<Stack className={classes.root}>
	// 		<Stack tokens={sectionStackTokens}>
	// 			<Stack className={classes.card}>
	// 				<Persona
	// 					{...personaWithInitials}
	// 					initialsColor={PersonaInitialsColor.lightBlue}
	// 					text={`${firstName} ${lastName}`}
	// 					size={PersonaSize.size72}
	// 				/>
	// 			</Stack>
	// 			{baq &&
	// 				baq.map((item) => {
	// 					return (
	// 						<Stack className={classes.cardMain}>
	// 							<Stack.Item className={classes.cardMainHeading}>
	// 								<Stack.Item>
	// 									<Text variant={"xLarge"}>{item.title}</Text>
	// 								</Stack.Item>
	// 							</Stack.Item>
	// 							<Stack horizontal className={classes.cardContent}>
	// 								<Stack tokens={horizontalGapStackTokens} grow={8} horizontal>
	// 									<Stack.Item
	// 										tokens={verticalStackTokens}
	// 										className={classes.smallCard}
	// 										grow={11}
	// 									>
	// 										<Stack.Item className={classes.count}>
	// 											<Text variant={"xxLarge"}>
	// 												{item.totalQuestions}
	// 											</Text>
	// 										</Stack.Item>
	// 										<Stack.Item>
	// 											<Text variant={"large"}>Total Questions</Text>
	// 										</Stack.Item>
	// 									</Stack.Item>
	// 									<Stack.Item
	// 										tokens={verticalStackTokens}
	// 										className={classes.smallCard}
	// 										grow={11}
	// 									>
	// 										<Stack.Item className={classes.count}>
	// 											<Text variant={"xxLarge"}>{item.answered}</Text>
	// 										</Stack.Item>
	// 										<Stack.Item>
	// 											<Text variant={"large"}>Answered Questions</Text>
	// 										</Stack.Item>
	// 									</Stack.Item>
	// 									<Stack.Item
	// 										tokens={verticalStackTokens}
	// 										className={classes.smallCard}
	// 										grow={11}
	// 									>
	// 										<Stack.Item className={classes.count}>
	// 											<Text variant={"xxLarge"}>{item.pending}</Text>
	// 										</Stack.Item>
	// 										<Stack.Item>
	// 											<Text variant={"large"}>Pending Questions</Text>
	// 										</Stack.Item>
	// 									</Stack.Item>
	// 									<Stack.Item
	// 										tokens={verticalStackTokens}
	// 										className={classes.smallCard}
	// 										grow={11}
	// 									>
	// 										<Stack.Item className={classes.count}>
	// 											<Text variant={"xxLarge"}>
	// 												{(
	// 													(item.answered / item.totalQuestions) *
	// 													100
	// 												).toFixed()}
	// 												%
	// 											</Text>
	// 											<Stack.Item>
	// 												<Text variant={"large"}>Completed</Text>
	// 											</Stack.Item>
	// 										</Stack.Item>
	// 									</Stack.Item>
	// 								</Stack>
	// 								<Stack grow={1}>
	// 									<PrimaryButton
	// 										style={{ marginTop: "39px" }}
	// 										text={
	// 											item.answered > 0 ? "CONTINUE" : "PROCEED TO ANSWER"
	// 										}
	// 										onClick={() => goToAnswer(item.formId)}
	// 									/>
	// 								</Stack>
	// 							</Stack>
	// 						</Stack>
	// 					);
	// 				})}
	// 			{baq.length && baq[0].reportId === 0 ? null : (
	// 				<Stack.Item className={classes.cardMain}>
	// 					<Stack.Item className={classes.cardMainHeading}>
	// 						<Stack horizontal={true}>
	// 							<Stack.Item grow={10}>
	// 								<Text variant={"xLarge"}>BA Report</Text>
	// 							</Stack.Item>
	// 						</Stack>
	// 					</Stack.Item>
	// 					<Stack className={classes.cardContent}>
	// 						<Stack.Item align="center">
	// 							<p style={{ fontSize: 22, textAlign: "center" }}>
	// 								Please download your Business Analysis Report
	// 							</p>
	// 						</Stack.Item>
	// 						<Stack.Item align="center">
	// 							<PrimaryButton onClick={onDownload}>DOWNLOAD REPORT</PrimaryButton>
	// 						</Stack.Item>
	// 					</Stack>
	// 				</Stack.Item>
	// 			)}
	// 		</Stack>
	// 	</Stack>
	// );
};
