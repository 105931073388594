import { Nav } from "@fluentui/react";
import { AuthStateContext, Module } from "authentication";
import React, { useContext } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { INavigationProps } from ".";
import { useStyles } from "./Navigation.styles";

export const Navigation: React.FC<INavigationProps> = (props) => {
	const { state, onLinkClick } = props;
	const { navLinkGroups, selectedKey, isOpen } = state;
	const match = useRouteMatch();
	const history = useHistory();
	const classes = useStyles(isOpen);
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const { userRolePermissions } = user;
	const isActive = (route: string) => {
		return history.location.pathname.includes(`${match.path}/${route}`) ? "active" : "";
	};
	return (
		<>
			<ul className="sidebar-menu">
				<li className={isActive("home")}>
					<Link className="nav-link" to={`${match.path}/home`}>
						<i className="fas fa-home"></i>Home
					</Link>
				</li>
				{userRolePermissions.some((c) => c.moduleId === Module.User && c.read) && (
					<li className={isActive("user")}>
						<Link className="nav-link" to={`${match.path}/user`}>
							<i className="fas fa-user-shield"></i>ProfiTune
						</Link>
					</li>
				)}
				{userRolePermissions.some((c) => c.moduleId === Module.BIP && c.read) && (
					<li className={isActive("bipuser")}>
						<Link className="nav-link" to={`${match.path}/bipuser`}>
							<i className="fas fa-user-tie"></i>Coach
						</Link>
					</li>
				)}
				{userRolePermissions.some((c) => c.moduleId === Module.Client && c.read) && (
					<li className={isActive("client")}>
						<Link className="nav-link" to={`${match.path}/client`}>
							<i className="fas fa-users"></i>Client
						</Link>
					</li>
				)}
				{userRolePermissions.some((c) => c.moduleId === Module.BAQ && c.read) && (
					<li className={isActive("questionare")}>
						<Link className="nav-link" to={`${match.path}/questionare`}>
							<i className="fas fa-file"></i> BAQ
						</Link>
					</li>
				)}
				{userRolePermissions.some((c) => c.moduleId === Module.Settings && c.read) && (
					<li className={isActive("settings")}>
						<Link className="nav-link" to={`${match.path}/settings`}>
							<i className="fas fa-cog"></i> Settings
						</Link>
					</li>
				)}
			</ul>
		</>
	);
	return (
		<div>
			<Nav
				onLinkClick={onLinkClick}
				selectedKey={selectedKey}
				ariaLabel="Nav basic example"
				className={classes.root}
				groups={navLinkGroups}
			/>
		</div>
	);
};
