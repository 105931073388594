import { Stack, Text } from "@fluentui/react";
import { FieldAttribute, FieldType, IFormFieldData, IFormFieldValue } from "admin/questionare";
import React from "react";
import { PreviewControl, QuestionControl } from "shared/containers";
import { IPF23ControlProps } from "./PF23Control.types";

export const PF23Control: React.FC<IPF23ControlProps> = (props) => {
	const { formFieldValues, parentId, fieldId, onChange } = props;

	const onValueChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		value?: string,
		valueId?: number,
		dataId?: number
	) => {
		onChange &&
			onChange(
				parentId,
				fieldId,
				formId,
				fieldAttribute,
				FieldType.PF23,
				value,
				valueId,
				dataId
			);
	};

	const getAdvisor = (): JSX.Element => {
		const formVal: IFormFieldValue = formFieldValues[0];
		const answer: IFormFieldData = formVal.answers[0];
		return (
			<PreviewControl
				label={formVal.title}
				parentId={parentId}
				fieldId={fieldId}
				formId={answer.formId}
				fieldType={FieldType.Text}
				value={answer.value}
				valueId={formVal.valueId}
				fieldAttribute={"PF_Advisor"}
				onChange={onValueChange}
			/>
		);
	};

	const getBusiness = (): JSX.Element => {
		const formVal: IFormFieldValue = formFieldValues[1];
		const answer: IFormFieldData = formVal.answers[0];
		return (
			<PreviewControl
				label={formVal.title}
				parentId={parentId}
				fieldId={fieldId}
				formId={answer.formId}
				fieldType={FieldType.Text}
				value={answer.value}
				valueId={formVal.valueId}
				fieldAttribute={"PF_Business"}
				onChange={onValueChange}
			/>
		);
	};

	const getContact = (): JSX.Element => {
		const formVal: IFormFieldValue = formFieldValues[2];
		const answer: IFormFieldData = formVal.answers[0];
		return (
			<PreviewControl
				label={formVal.title}
				parentId={parentId}
				fieldId={fieldId}
				formId={answer.formId}
				fieldType={FieldType.Number}
				value={answer.value}
				valueId={formVal.valueId}
				fieldAttribute={"PF_Contact"}
				onChange={onValueChange}
			/>
		);
	};

	const goodCheckBox = (): JSX.Element => {
		const formVal: IFormFieldValue = formFieldValues[3];
		const answer: IFormFieldData = formVal.answers[0];
		return (
			<PreviewControl
				label={formVal.title}
				parentId={parentId}
				fieldId={fieldId}
				formId={answer.formId}
				fieldType={FieldType.Checkbox}
				value={answer.value}
				valueId={formVal.valueId}
				fieldAttribute={"PF_Good"}
				onChange={onValueChange}
			/>
		);
	};

	const okCheckBox = (): JSX.Element => {
		const formVal: IFormFieldValue = formFieldValues[4];
		const answer: IFormFieldData = formVal.answers[0];
		return (
			<PreviewControl
				label={formVal.title}
				parentId={parentId}
				fieldId={fieldId}
				formId={answer.formId}
				fieldType={FieldType.Checkbox}
				value={answer.value}
				valueId={formVal.valueId}
				fieldAttribute={"PF_Ok"}
				onChange={onValueChange}
			/>
		);
	};

	const poorCheckBox = (): JSX.Element => {
		const formVal: IFormFieldValue = formFieldValues[5];
		const answer: IFormFieldData = formVal.answers[0];
		return (
			<PreviewControl
				label={formVal.title}
				parentId={parentId}
				fieldId={fieldId}
				formId={answer.formId}
				fieldType={FieldType.Checkbox}
				value={answer.value}
				valueId={formVal.valueId}
				fieldAttribute={"PF_Poor"}
				onChange={onValueChange}
			/>
		);
	};

	const naCheckBox = (): JSX.Element => {
		const formVal: IFormFieldValue = formFieldValues[6];
		const answer: IFormFieldData = formVal.answers[0];
		return (
			<PreviewControl
				label={formVal.title}
				parentId={parentId}
				fieldId={fieldId}
				formId={answer.formId}
				fieldType={FieldType.Checkbox}
				value={answer.value}
				valueId={formVal.valueId}
				fieldAttribute={"PF_N/A"}
				onChange={onValueChange}
			/>
		);
	};

	const noneCheckBox = (): JSX.Element => {
		const formVal: IFormFieldValue = formFieldValues[7];
		const answer: IFormFieldData = formVal.answers[0];
		return (
			<PreviewControl
				label={formVal.title}
				parentId={parentId}
				fieldId={fieldId}
				formId={answer.formId}
				fieldType={FieldType.Checkbox}
				value={answer.value}
				valueId={formVal.valueId}
				fieldAttribute={"PF_None"}
				onChange={onValueChange}
			/>
		);
	};
	return (
		<>
			<div className="row">
				<div className="col-md-6">
					{getAdvisor()}

					{getContact()}
				</div>
				<div className="col-md-6">
					{getBusiness()}
					<div className="mt-5">
						<div className="form-check-inline">{goodCheckBox()}</div>
						<div className="form-check-inline">{okCheckBox()}</div>
						<div className="form-check-inline">{poorCheckBox()}</div>
						<div className="form-check-inline">{naCheckBox()}</div>
						<div className="form-check-inline">{noneCheckBox()}</div>
					</div>
				</div>
			</div>
		</>
	);
	return (
		<Stack tokens={{ childrenGap: 0, padding: 0 }}>
			<Stack horizontal tokens={{ childrenGap: 15, padding: 10 }}>
				<Stack grow={6}>{getAdvisor()}</Stack>
				<Stack grow={6}>{getBusiness()}</Stack>
			</Stack>
			<Stack horizontal tokens={{ childrenGap: 15, padding: 10 }}>
				<Stack grow={6}>{getContact()}</Stack>
				<Stack grow={6} style={{ marginTop: "7px" }}>
					<Stack.Item>
						<Text style={{ marginLeft: "10px" }} variant={"mediumPlus"}>
							Quality of Advice
						</Text>
					</Stack.Item>
					<Stack horizontal tokens={{ childrenGap: 15, padding: 10 }}>
						{goodCheckBox()}
						{okCheckBox()}
						{poorCheckBox()}
						{naCheckBox()}
						{noneCheckBox()}
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};
