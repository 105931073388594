import React from "react";
import "./AnswerNav.css";
import { Link, useHistory } from "react-router-dom";
import { useImmer } from "use-immer";
import { Collapse, UncontrolledCollapse } from "reactstrap";

export const AnswerNav = (props) => {
	const [menu, setMenu] = useImmer({
		activeKey: parseInt(props.activeKey),
		links: props.menu[0].links,
	});
	const errors = props.errors.errors;
	const history = useHistory();

	const onLinkClick = (ev, item) => {
		if (item) {
			const urlLink = `${item.url}`;
			history.replace(urlLink);
		}
		// ev && ev.preventDefault();
	};

	const Expand = (key) => {
		setMenu((draft) => {
			draft.links[key].isExpanded = !draft.links[key].isExpanded;
		});
	};

	const isActive = (key) => {
		// console.log("active key",menu);
		if (menu.activeKey === parseInt(key)) {
			return "active";
		} else {
			return "";
		}
	};

	const getIcon = (item) => {
		if (item.links !== undefined && item.links.length > 0) {
			if (errors.some((s) => s.sectionParent?.toString() === item.key)) {
				return "error";
			} else if (item.links.some((c) => c.answered > 0 && c.answered !== c.total)) {
				return "warning";
			} else if (
				item.links.filter((c) => c.answered === c.total).length === item.links.length
			) {
				return "success";
			} else {
				return "partially";
			}
		} else {
			if (errors.some((s) => s.sectionId.toString() === item.key)) {
				return "error";
			} else if (item.total === item.answered) {
				return "success";
			} else if (item.answered > 1 && item.answered < item.total) {
				return "warning";
			} else {
				return "partially";
			}
		}
	};

	const LoadMenu = () => {
		return (
			<>
				{menu.links &&
					menu.links.map((item, index) => {
						return (
							<div className="card">
								<div
									className={
										"questionnaire-card-header " +
										`${getIcon(item)} ${isActive(item.key)}`
									}
									id="headingOne"
								>
									<h2>
										<button
											className={
												"btn " + (item.isExpanded ? "" : "collapsed")
											}
											type="button"
											id={"toggler" + item.key}
											onClick={(event) => {
												Expand(index);
												onLinkClick(event, item);
											}}
										>
											{item.name}
											{item.links.length > 0 && (
												<i className="icon-expand-arrow" />
											)}
										</button>
									</h2>
								</div>
								{item.links && (
									<Collapse isOpen={item.isExpanded}>
										{item.links.map((submenu) => {
											return (
												<div className="custom-card-body">
													<ul className="questionnaire-list">
														<li
															className={`${getIcon(
																submenu
															)} ${isActive(submenu.key)}`}
															onClick={(event) =>
																onLinkClick(event, submenu)
															}
														>
															{submenu.name}
															{/* <Link to={submenu.url}>
																{submenu.name}
															</Link> */}
														</li>
													</ul>
												</div>
											);
											// return (
											// 	<li
											// 		className={isActive(submenu.key)}
											// 		key={submenu.key}
											// 	>
											// 		<Link to={submenu.url}>{submenu.name} </Link>
											// 		<div className="progress-indicator">
											// 			<img
											// 				src={`/icon_${getIcon(submenu)}.png`}
											// 				alt="Completed Icon"
											// 			/>
											// 		</div>
											// 	</li>
											// );
										})}
									</Collapse>
								)}
							</div>
						);
						return (
							<li className={isActive(item.key)} key={item.key}>
								<Link to={item.url}>{item.name}</Link>
								<span className="progress-indicator">
									<img src={`/icon_${getIcon(item)}.png`} alt="Completed Icon" />
								</span>
								{item.links.length > 0 && (
									<span
										className="submenu-indicator"
										onClick={() => Expand(index)}
									>
										{item.isExpanded ? "-" : "+"}
									</span>
								)}
								{/* {item.links && <LoadMenu submenu={item.links} />} */}
								<ul className={`submenu ${item.isExpanded && "show"}`}>
									{item.links &&
										item.links.map((submenu) => {
											return (
												<li
													className={isActive(submenu.key)}
													key={submenu.key}
												>
													<Link to={submenu.url}>{submenu.name} </Link>
													<div className="progress-indicator">
														<img
															src={`/icon_${getIcon(submenu)}.png`}
															alt="Completed Icon"
														/>
													</div>
												</li>
											);
										})}
								</ul>
							</li>
						);
					})}
			</>
		);
	};
	// const LoadMenu = () => {
	// 	return (
	// 		<>
	// 			{menu.links &&
	// 				menu.links.map((item, index) => {
	// 					return (
	// 						<li className={isActive(item.key)} key={item.key}>
	// 							<Link to={item.url}>{item.name}</Link>
	// 							<span className="progress-indicator">
	// 								<img src={`/icon_${getIcon(item)}.png`} alt="Completed Icon" />
	// 							</span>
	// 							{item.links.length > 0 && (
	// 								<span
	// 									className="submenu-indicator"
	// 									onClick={() => Expand(index)}
	// 								>
	// 									{item.isExpanded ? "-" : "+"}
	// 								</span>
	// 							)}
	// 							{/* {item.links && <LoadMenu submenu={item.links} />} */}
	// 							<ul className={`submenu ${item.isExpanded && "show"}`}>
	// 								{item.links &&
	// 									item.links.map((submenu) => {
	// 										return (
	// 											<li
	// 												className={isActive(submenu.key)}
	// 												key={submenu.key}
	// 											>
	// 												<Link to={submenu.url}>{submenu.name} </Link>
	// 												<div className="progress-indicator">
	// 													<img
	// 														src={`/icon_${getIcon(submenu)}.png`}
	// 														alt="Completed Icon"
	// 													/>
	// 												</div>
	// 											</li>
	// 										);
	// 									})}
	// 							</ul>
	// 						</li>
	// 					);
	// 				})}
	// 		</>
	// 	);
	// };

	return (
		<>
			<div className="item-block nav-bar ">
				<div className="accordion questionnaire-card" id="accordionExample">
					<LoadMenu />
				</div>
			</div>
		</>
	);

	return (
		<>
			<div id="answer-menu" className="answer-menu">
				<div className="answer-menu-header">BAQ </div>
				<ul>
					<LoadMenu />
				</ul>
			</div>
		</>
	);
};
