import { Spinner } from "reactstrap";
import { Input } from "../components/Controls/Input";
import {
	Login,
	onChangeEmail,
	onChangePassword,
	requiredValidation,
	UserAuthDispatcher,
} from "authentication/auth.action";
import { AuthStateContext } from "authentication/useAuthContext";
import React, { useContext, useState } from "react";
import { IClientSignInProps } from ".";
import { AvForm } from "availity-reactstrap-validation";
import { ForgotPassword } from "./ForgotPassword";

export const ClientSignIn: React.FC<IClientSignInProps> = (props) => {
	const userAuthDispatcher = UserAuthDispatcher();
	const authState = useContext(AuthStateContext);
	const [toggleForgot, setToggleForgot] = useState(false);

	const { credential } = authState;
	const { emailAddress, password } = credential;

	const toggleForgotClick = () => {
		setToggleForgot(!toggleForgot);
	};

	const logIn = () => {
		if (emailAddress && password) {
			Login(userAuthDispatcher, credential);
		} else {
			requiredValidation(userAuthDispatcher);
		}
	};
	const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChangeEmail(userAuthDispatcher, event.target.value);
	};

	const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChangePassword(userAuthDispatcher, event.target.value);
	};

	return (
		<>
			<section className="authentication">
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-4">
							<div className="auth-form">
								<h1 className="logo">
									<a href="index.html">
										<img src="./images/profitune-logo.svg" />
									</a>
								</h1>
								{toggleForgot === true ? (
									<ForgotPassword toggleClick={toggleForgotClick} />
								) : (
									<>
										<h3 className="head-style-01">
											Log in to your Questionnaire
										</h3>
										<AvForm className="base-form">
											<Input
												type="email"
												name="email"
												label="Email"
												required="true"
												autoComplete="off"
												value={emailAddress}
												onChange={onEmailChange}
											/>
											<Input
												type="password"
												name="password"
												label="Password"
												required="true"
												value={password}
												onChange={onPasswordChange}
											/>
											<div className="form-forgot">
												<div className="checkbox">
													<input
														type="checkbox"
														id="remember"
														name="remember"
														defaultValue="remember"
													/>
													<label className="checkbox" htmlFor="remember">
														Remember Me
													</label>
												</div>
												<a onClick={toggleForgotClick} className="link">
													Forgot password?
												</a>
											</div>
											<button
												type="submit"
												className="primary-button"
												onClick={logIn}
											>
												LOGIN {authState.isBusy && <Spinner size="sm" />}
											</button>
											{/* <div className="signup-link">
												No account ? <a href="signup.html">Sign Up</a>
											</div> */}
										</AvForm>
									</>
								)}
							</div>
						</div>
						<div className="col-sm-8 auth-bg"></div>
					</div>
				</div>
			</section>
		</>
	);
	// return (
	// 	<Stack horizontal className={styles.root}>
	// 		<Stack className={styles.login} grow={1}>
	// 			<Stack className={styles.loginInnerConent} tokens={verticalGapStackTokens}>
	// 				<Stack.Item>
	// 					<img src="/profi_logo.png" alt="Profitune Logo"></img>
	// 				</Stack.Item>
	// 				<Stack.Item>
	// 					<Text variant={"xxLarge"}>Login in to your account</Text>
	// 				</Stack.Item>
	// 				{errorMessage && (
	// 					<Stack.Item>
	// 						<span style={{ color: "red" }}>{errorMessage}</span>
	// 					</Stack.Item>
	// 				)}
	// 				<Stack.Item>
	// 					<TextField
	// 						type={"email"}
	// 						value={emailAddress}
	// 						onChange={onEmailChange}
	// 						label="Email"
	// 						errorMessage={emailAddressErrorMessage}
	// 					/>
	// 				</Stack.Item>
	// 				<Stack.Item>
	// 					<TextField
	// 						type={"password"}
	// 						value={password}
	// 						label="Password"
	// 						onChange={onPasswordChange}
	// 						errorMessage={passwordErrorMessage}
	// 					/>
	// 				</Stack.Item>
	// 				<Stack horizontal tokens={verticalGapStackTokens}>
	// 					<Stack.Item grow={1} align={"start"}>
	// 						<Checkbox label="Remeber me" />
	// 					</Stack.Item>
	// 					<Stack.Item align={"end"}>
	// 						<Link href="">Forgot password?</Link>
	// 					</Stack.Item>
	// 				</Stack>
	// 				<Stack.Item>
	// 					<PrimaryButton style={{ width: "100%" }} text="Login" onClick={logIn} />
	// 				</Stack.Item>
	// 				{authState.isBusy && (
	// 					<Stack.Item>
	// 						<Spinner
	// 							label="Signing In..."
	// 							ariaLive="assertive"
	// 							labelPosition="right"
	// 						/>
	// 					</Stack.Item>
	// 				)}
	// 			</Stack>
	// 		</Stack>
	// 		<Stack grow={8}>
	// 			<Image
	// 				{...imageProps}
	// 				alt='Example of the image fit value "center" on an image larger than the frame.'
	// 			/>
	// 		</Stack>
	// 	</Stack>
	// );
};
