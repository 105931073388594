import React from "react";

import { IAnswerContainerProps, IAnswerDescriptionQuery, useAnswer } from ".";
import { ICommandBarItemProps } from "@fluentui/react";
import { Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import ProtectedRoute from "authentication/ProtectedRoute";
import { AnswerHolder } from "./AnswerHolder";
import { FieldType, ISection } from "admin/questionare";
import { AnswerNav } from "client/components";
import { useError } from "shared/hooks";
import { ErrorDialog } from "shared/components";
import { ToastContainer } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";

export const AnswerContainer: React.FC<IAnswerContainerProps> = (props) => {
	const { AnswerViewType, goToEdit } = props;
	const queryString = require("query-string");
	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch();
	const { errorstate, onErrorClose, onError } = useError();
	const answerQuery = queryString.parse(location.search) as IAnswerDescriptionQuery;
	if (AnswerViewType === "Preview") {
		answerQuery.client = 0;
	}

	const goToHome = () => {
		history.push("/client/home");
	};

	const { state, onChange, onSave, removeOption, addOption, onSubmit } = useAnswer(
		answerQuery.client,
		answerQuery.form,
		answerQuery.section,
		AnswerViewType,
		onError,
		goToHome
	);
	const {
		questions,
		isFormDirty,
		isBusy,
		isLoadingSections,
		next,
		previous,
		formSummary,
		autoSaveTime,
		errors,
	} = state;

	React.useEffect(() => {
		window.scrollTo(0, 0);
		if (answerQuery.section === undefined && state.sections.length > 0) {
			const link = `${match.path}?form=${answerQuery.form}&section=${state.sections[0].sectionId}&client=${answerQuery.client}`;
			history.replace(link);
		}
	}, [
		answerQuery.client,
		answerQuery.form,
		answerQuery.section,
		history,
		match.path,
		state.sections,
		state.sections.length,
	]);

	const handleOnIdle = (event: Event) => {
		if (!questions.find((s) => s.fieldType === FieldType.Likert)) {
			onSave(answerQuery.client, false, AnswerViewType, "AutoSave");
		}
	};

	useIdleTimer({
		timeout: 3000,
		onIdle: handleOnIdle,
		debounce: 500,
	});

	const getProgress = (): JSX.Element | null => {
		if (formSummary && AnswerViewType === "Answer") {
			const { totalQuestions, answered } = formSummary;
			const percentage = ((answered / totalQuestions) * 100).toFixed(2);

			return (
				<div className="item-block m-b-20 progress-item">
					<div className="item-padd-small progress-wrap">
						<h3>Progress</h3>
						<div className="progress-block">
							<label>
								<span>{answered} Q</span> <span>{totalQuestions} Q</span>
							</label>
							<div className="progress-bar">
								<span style={{ width: `${percentage}%` }} />
							</div>
							<p>
								{answered && (parseInt(percentage) >= 100 ? "100" : percentage)}%
								Completed
							</p>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	};

	const _farItems: ICommandBarItemProps[] = [
		// {
		// 	key: "save",
		// 	text: "Save asdf",
		// 	ariaLabel: "Save",
		// 	iconProps: { iconName: "Save" },
		// 	disabled: !isDirty,
		// },
	];
	if (AnswerViewType === "Answer") {
		_farItems.push({
			key: "save",
			text: "Save",
			ariaLabel: "Save",
			iconProps: { iconName: "Save" },
			disabled: !isFormDirty,
			onClick: () => {
				onSave(answerQuery.client, true, AnswerViewType, "ButtonSave");
			},
		});
	}
	if (AnswerViewType === "Preview" && goToEdit) {
		_farItems.push({
			key: "close",
			text: "Close",
			ariaLabel: "Close",
			iconProps: { iconName: "ChromeClose" },
			onClick: () => {
				goToEdit(answerQuery);
			},
		});
	}

	const isParentExpanded = (section: ISection) => {
		return section.subSection &&
			section.subSection.filter((c) => c.sectionId === +answerQuery.section).length > 0
			? true
			: false;
	};

	const getNavLinkGroup = () => {
		let navLinkGroups = [{ links: [] as any }];
		state.sections.forEach((section: ISection, index: number) => {
			const link = {
				links: [] as any,
				isExpanded: isParentExpanded(section),
				key: section.sectionId.toString(),
				name: section.name,
				total: section.totalQuestions,
				answered: section.answered,
				url: `${match.path}?form=${answerQuery.form}&section=${section.sectionId}&&client=${answerQuery.client}`,
			};
			section.subSection &&
				section.subSection.forEach((section: ISection, index: number) => {
					const childLink = {
						isExpanded: isParentExpanded(section),
						key: section.sectionId.toString(),
						name: section.name,
						total: section.totalQuestions,
						answered: section.answered,
						url: `${match.path}?form=${answerQuery.form}&section=${section.sectionId}&client=${answerQuery.client}`,
					};
					link.links?.push(childLink);
				});
			navLinkGroups[0].links.push(link);
		});
		return navLinkGroups;
	};

	const onNextClick = () => {
		onSave(answerQuery.client, false, AnswerViewType, "PaginationSave");
		const link = `${match.path}?form=${answerQuery.form}&section=${state.next.sectionId}&client=${answerQuery.client}`;
		history.replace(link);
	};

	const onPreviousClick = () => {
		onSave(answerQuery.client, false, AnswerViewType, "PaginationSave");
		const link = `${match.path}?form=${answerQuery.form}&section=${state.previous.sectionId}&client=${answerQuery.client}`;
		history.replace(link);
	};

	return (
		<>
			<Switch>
				<ProtectedRoute
					exact={true}
					path={`${match.path}`}
					render={() => {
						return (
							<>
								<ToastContainer></ToastContainer>
								<div className="autosave-info">
									<div className="container">
										{autoSaveTime && "Last Saved on " + autoSaveTime}
										{AnswerViewType === "Answer" && (
											<button
												className="save-btn"
												disabled={!isFormDirty}
												onClick={() =>
													onSave(
														answerQuery.client,
														true,
														AnswerViewType,
														"ButtonSave"
													)
												}
											>
												<i className="icon-save" /> Save
											</button>
										)}
										{AnswerViewType === "Preview" && goToEdit && (
											<button
												className="save-btn"
												onClick={() => goToEdit(answerQuery)}
											>
												<i className="icon-save" /> Close
											</button>
										)}
									</div>
								</div>

								<div className="container-wrapper">
									<div className="container">
										<div className="row">
											<div className="col-lg-4">
												<div className="ans-sidebar">
													{getProgress()}
													{!isLoadingSections && (
														<AnswerNav
															errors={errors}
															menu={getNavLinkGroup()}
															activeKey={answerQuery.section}
														/>
													)}
												</div>
											</div>
											<div className="col-lg-8">
												<ErrorDialog
													errorMessage={errorstate.errorMessage}
													show={errorstate.visible}
													onClose={onErrorClose}
												/>
												{/* <div className="intro-head">Introduction</div> */}
												<div
													className="baq-wrapper"
													style={{ overflow: "auto" }}
												>
													<AnswerHolder
														errors={errors}
														isBusy={isBusy}
														next={next}
														previous={previous}
														questions={questions}
														onNextClick={onNextClick}
														onPreviousClick={onPreviousClick}
														addOption={addOption}
														onChange={onChange}
														removeOption={removeOption}
													/>
												</div>
												<div className="m-b-20 mt-3">
													<div className="pagination">
														<button
															className="btn-primary-c w-auto"
															disabled={previous.disable}
															onClick={() => onPreviousClick()}
														>
															PREV
														</button>
														{!next.disable && (
															<button
																className="btn-primary-c w-auto"
																disabled={next.disable}
																onClick={() => onNextClick()}
															>
																NEXT
															</button>
														)}
														{next.disable &&
															AnswerViewType === "Answer" && (
																<button
																	className="btn-primary-c w-auto"
																	onClick={() =>
																		onSubmit(answerQuery)
																	}
																	// disabled={
																	// 	formSummary.pending === 0
																	// 		? false
																	// 		: true
																	// }
																>
																	SUBMIT
																</button>
															)}
													</div>
												</div>
											</div>
										</div>
										{/* <div className="row d-flex justify-content-end">
											<div className="col-md-8">
												<div className="m-b-20">
													<div className="pagination">
														<button
															className="btn-primary w-auto"
															disabled={previous.disable}
															onClick={() => onPreviousClick()}
														>
															PREV
														</button>
														<button
															className="btn-primary w-auto"
															disabled={next.disable}
															onClick={() => onNextClick()}
														>
															NEXT
														</button>
													</div>
												</div>
											</div>
										</div> */}
									</div>
								</div>
							</>
						);
					}}
				/>
			</Switch>
		</>
	);
	// return (
	// 	<Switch>
	// 		<ProtectedRoute
	// 			exact={true}
	// 			path={`${match.path}`}
	// 			render={() => {
	// 				return (
	// 					<Stack horizontal className={classes.root}>
	// 						<Stack.Item grow={11} className={classes.formHolder}>
	// 							<CommandBar
	// 								style={{
	// 									borderStyle: "solid",
	// 									borderColor: "#eeeeee",
	// 									borderWidth: "1px",
	// 								}}
	// 								items={[]}
	// 								farItems={_farItems}
	// 							/>
	// 							<Stack>
	// 								<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 							</Stack>
	// 							<Stack horizontal>
	// 								<Stack tokens={{ childrenGap: 15, padding: 10 }}>
	// 									{getProgress()}
	// 									{!isLoadingSections && (
	// 										<AnswerNav
	// 											menu={getNavLinkGroup()}
	// 											activeKey={activeSection}
	// 										/>
	// 									)}
	// 								</Stack>
	// 								<AnswerHolder
	// 									isBusy={isBusy}
	// 									next={next}
	// 									previous={previous}
	// 									questions={questions}
	// 									onNextClick={onNextClick}
	// 									onPreviousClick={onPreviousClick}
	// 									addOption={addOption}
	// 									onChange={onChange}
	// 									removeOption={removeOption}
	// 								/>
	// 							</Stack>
	// 						</Stack.Item>
	// 					</Stack>
	// 				);
	// 			}}
	// 		/>
	// 	</Switch>
	// );
};
