/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, useContext, useState } from "react";
import { baseUrl } from "api/config";
import Axios from "axios";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ErrorDialog, Loading } from "shared/components";
import { useImmer } from "use-immer";
import { IClientDetailsProps, IClientQuery, useClient } from ".";
import { getReports } from "..";
import { useStyles } from "./ClientDetails.styles";
import { IReportDetails } from "./useClient.types";
import { IFormSummary, ITemplate } from "admin/questionare";
import { useError } from "shared/hooks";
import { Breadcrumb, BreadcrumbItem, Input, Spinner } from "reactstrap";
import { AuthStateContext, UserType } from "authentication";
import { FormConfig } from "admin/questionare/containers/Question/FormConfig";
import { ToastContainer, toast } from "react-toastify";

export const ClientDetails: React.FC<IClientDetailsProps> = (props) => {
	const authState = useContext(AuthStateContext);
	const [baq, setBaq] = useImmer([] as any);
	const [selectedBAQ, setSelectedBAQ] = useImmer({} as any);
	const [templates, setTemplates] = useImmer<ITemplate[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useImmer<string>("");
	const [reportDate, setReportDate] = useImmer<string>(
		new Date().toISOString().substr(0, 10).toString()
	);
	//const [publishStatus, setPublishStatus] = useImmer<boolean>(false);
	const { mode, onEdit, onClientNavigateBack } = props;
	const queryString = require("query-string");
	const location = useLocation();
	const classes = useStyles();
	const clientUserQuery = queryString.parse(location.search) as IClientQuery;
	const { errorstate, onErrorClose, onError } = useError();
	const { state, onDownload, onExport, onUploadReport, onRemoveReport, dispatcher } = useClient(
		mode,
		clientUserQuery,
		onError
	);
	const { user } = authState;
	const { selectedClient, reports } = state;
	const { emailAddress, firstName, lastName, clientId, businessName, mobile } = selectedClient;
	const fileInputRef = React.useRef<HTMLInputElement>(null);
	const [modal, setModal] = useState(false);
	const [busy, setBusy] = useImmer({
		BAQ: false,
	} as any);
	// useEffect(() => {
	// 	if (reports.length > 0) {
	// 		Axios.post(baseUrl + `Report/Publish/${reports[0].reportId}`)
	// 			.then((res) => {
	// 				setPublishStatus((draft) => {
	// 					draft = res.data;
	// 					return draft;
	// 				});
	// 			})
	// 			.catch((err) => {
	// 				console.log(err);
	// 			});
	// 	}
	// }, [reports, reports.length, selectedClient.clientId, setBaq, setPublishStatus]);

	useEffect(() => {
		if (selectedClient.clientId) {
			setBusy((draft) => {
				draft.BAQ = true;
				return draft;
			});
			Axios.get(baseUrl + `Form/GetBAQList/${selectedClient.clientId}`)
				.then((res) => {
					setBaq(() => {
						return res.data;
					});
					setBusy((draft) => {
						draft.BAQ = false;
						return draft;
					});
				})
				.catch((err) => {
					setBusy((draft) => {
						draft.BAQ = false;
						return draft;
					});
					onError(err.response.data);
				});
		}
	}, [onError, selectedClient.clientId, setBaq]);

	useEffect(() => {
		if (selectedBAQ.formId) {
			Axios.get(baseUrl + "Report/Templates/" + selectedBAQ.formId)
				.then((res) => {
					const templates: ITemplate[] = res.data;
					setTemplates((draft) => {
						draft = templates;
						return draft;
					});
				})
				.catch((err) => {
					onError(err.response.data);
				});
		}
	}, [onError, selectedBAQ, setTemplates]);

	useEffect(() => {
		var enabledBAQ = baq.filter((c: any) => c.isEnabled);
		setSelectedBAQ(() => {
			return enabledBAQ.length > 0 ? enabledBAQ[0] : baq[0];
		});
	}, [baq, setSelectedBAQ]);

	const initial = {
		userId: authState.user.userType === UserType.Admin ? 0 : authState.user.userId,
		formula: {
			yearlyInt: 0,
			taxPc: 0,
			marketingCost: 0,
			referralConversionRate: 0,
			conversionRateIncrease: 0,
			productIncrease: 0,
			salesIncrease: 0,
			cosReduce: 0,
			bankFeesSavingRate: 0,
			insuranceSavingRate: 0,
			merchantFeesSavingRate: 0,
			telephoneCostsSavingRate: 0,
			printingCostsSavingRate: 0,
			rentSavingRate: 0,
			utilitiesSavingRate: 0,
			bottleNeckReductionRate: 0,
			professionalFeesReductionRate: 0,
			vehicleExpensesReductionRate: 0,
			salesBaseIncreaseRate: 0,
			overStockSavingRate: 0,
			overstaffingRate: 0,
		},
	};

	const [formInfo, setFormInfo] = useImmer(initial as any);
	useEffect(() => {
		if (selectedBAQ.formId && !selectedBAQ.calcConfig) {
			Axios.get(baseUrl + `form/config/${selectedBAQ.formId}/${initial.userId}`).then(
				(res) => {
					if (!res.data.formula) {
						setFormInfo((draft) => {
							return initial;
						});
					} else {
						setFormInfo((draft) => {
							return res.data;
						});
					}
				}
			);
		} else {
			setFormInfo((draft) => {
				return selectedBAQ.calcConfig;
			});
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBAQ]);

	const toggle = () => {
		setModal(!modal);
	};
	const onSubmit = () => {
		Axios.post(
			baseUrl + `form/baconfig/${selectedClient.clientId}/${selectedBAQ.formId}`,
			formInfo
		).then((res) => {
			if (!res.data.formula) {
				setFormInfo((draft) => {
					return initial;
				});
			} else {
				setFormInfo((draft) => {
					return res.data;
				});
			}
		});
		setModal(!modal);
	};
	const onChange = (event: any) => {
		const { name, value } = event.target;
		const reg = /^(\d{0,2})?(\.\d{0,3})?$/;
		if (reg.test(value)) {
			setFormInfo((draft) => {
				draft.formula[name] = +value;
				return draft;
			});
		}
	};

	const onDateChange = (event: any) => {
		let value = event.target.value;
		setReportDate((draft) => {
			return value;
		});
	};

	const onBAQChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		let selected = baq.filter((c: any) => c.formId === parseInt(event.target.value));
		if (baq.length > 0 && event.target !== null) {
			setSelectedBAQ(() => {
				return selected.length === 1 ? selected[0] : baq[0];
			});
		}
	};

	const onTemplateChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		let selected = event.target.value;
		setSelectedTemplate((draft) => {
			return selected;
		});
	};

	const handleClick = () => {
		setBusy((draft) => {
			draft.BAQ = true;
			return draft;
		});
		Axios.post(
			baseUrl +
				`Form/SetBAQ/${selectedClient.clientId}/${
					selectedBAQ.formId
				}/${!selectedBAQ.isEnabled}`
		)
			.then((res) => {
				toast(`BAQ ${!selectedBAQ.isEnabled ? "Enabled for answering" : "Disabled"}`);
				if (parseInt(res.data) > 0)
					setSelectedBAQ((draft) => {
						draft.isEnabled = !selectedBAQ.isEnabled;
					});
				setBusy((draft) => {
					draft.BAQ = false;
					return draft;
				});
			})
			.catch((err) => {
				setBusy((draft) => {
					draft.BAQ = false;
					return draft;
				});
				onError(err.response.data);
			});
	};

	const goToAnswering = () => {
		if (selectedBAQ.isEnabled) {
			window.open(
				`/client/answer?client=${clientUserQuery.clientId}&form=${selectedBAQ.formId}`
			);
		}
	};

	// if (state.isBusy) {
	// 	return <Loading />;
	// }

	const disableIntermediateReport =
		selectedBAQ && selectedBAQ.answered && selectedBAQ.isSubmited && !state.reportLoaderIsBusy
			? false
			: true;
	const publishButtonDisabled = reports.length > 0 ? false : true;

	const publishReport = () => {
		Axios.post(baseUrl + `Report/Publish/${reports[0].reportId}`)
			.then((res) => {
				getReports(dispatcher, selectedClient.clientId, onError);
			})
			.catch((err) => {
				onError(err.response.data);
			});
	};

	const onGenerateDownload = () => {
		onDownload(selectedBAQ.formId, clientId, selectedTemplate, reportDate);
	};

	const onExportAnswersClick = () => {
		onExport(selectedBAQ.formId, clientId);
	};

	const handleUploadButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChanged = () => {
		const fileInput = fileInputRef.current;
		const files = fileInput?.files;
		if (files && files.length > 0) {
			const arrFiles = Array.from(files);
			onUploadReport(arrFiles, selectedBAQ.formId, clientId);
		}
	};
	const getShortName = () => {
		return `${firstName?.charAt(0).toString()}${lastName?.charAt(0).toString()}`;
	};
	const getCircle = (percent: number) => {
		var circumference = 100 * 2 * Math.PI;
		let currentStyle: CSSProperties = {};
		currentStyle.strokeDasharray = `${circumference} ${circumference}`;
		currentStyle.strokeDashoffset = circumference - (percent / 100) * circumference;
		return currentStyle;
	};

	const handleInvite = () => {
		Axios.post(baseUrl + `client/invite/${selectedClient.clientId}`)
			.then((res) => {
				toast("Invitation has been send to the client");
			})
			.catch((err) => console.log(err));
	};

	return (
		<>
			<ToastContainer></ToastContainer>
			<div className="row">
				<div className="col-md-12">
					<div className="float-right">
						<button
							className="btn btn-primary mb-4"
							onClick={() => onEdit(clientUserQuery.clientId)}
						>
							<i className="fas fa-user-edit"></i> Edit Client
						</button>
					</div>
				</div>
			</div>
			<Breadcrumb tag="nav" listTag="div">
				<BreadcrumbItem
					tag={Link}
					to={`/${user.userType === UserType.BIP ? "bip" : "admin"}/home`}
				>
					Dashboard
				</BreadcrumbItem>
				<BreadcrumbItem
					tag={Link}
					to={`/${user.userType === UserType.BIP ? "bip" : "admin"}/client`}
				>
					Client
				</BreadcrumbItem>
				<BreadcrumbItem active>Details</BreadcrumbItem>
			</Breadcrumb>
			{/* <div className="container-wrapper">
				<div className="container"> */}
			<div>
				<ErrorDialog
					show={errorstate.visible}
					errorMessage={errorstate.errorMessage}
					onClose={onErrorClose}
				/>
			</div>
			{state.isBusy ? (
				<div className="d-flex justify-content-center align-items-center">
					<Spinner color="primary">Loading!</Spinner>
				</div>
			) : (
				<>
					<div className="item-block">
						<div className="item-padd">
							<div className="row">
								<div className="col-sm-6 col-lg-8">
									<div className="user-info">
										<figure className="user-pic">{getShortName()}</figure>
										<div className="user-details">
											<h2>
												{firstName} {lastName}
											</h2>
											<p>
												<span>{emailAddress}</span>
											</p>
											<p>{businessName}</p>
											<p>{mobile}</p>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-lg-4">
									<button
										className="btn btn-outline-primary float-right"
										onClick={handleInvite}
									>
										Send Invite
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="item-block">
						<div className="item-head d-flex justify-content-between">
							<h3>BA Questionnaire</h3>
							<div className="d-flex">
								<div className="mr-2">
									<button
										disabled={!selectedBAQ.isEnabled}
										className="btn btn-outline-dark ml-2"
										onClick={toggle}
									>
										<i className="fas fa-cog"></i> Calculation Settings
									</button>
								</div>
								<div>
									<select
										className="form-control"
										value={selectedBAQ.formId}
										onChange={onBAQChange}
										disabled={selectedBAQ.isEnabled}
									>
										{baq.map((item: any, index: number) => {
											return (
												<option value={item.formId}>{item.title}</option>
											);
										})}
									</select>
								</div>
								<button
									className="btn btn-primary ml-2"
									onClick={() => handleClick()}
								>
									{selectedBAQ.isEnabled ? "Disable BAQ" : "Enable BAQ"}
								</button>
								<button
									className="btn btn-primary ml-2"
									onClick={() => onExportAnswersClick()}
								>
									Export Answers
								</button>
							</div>

							{/* <div className="col-md-4">
								<select
									className="form-control"
									value={selectedBAQ.formId}
									onChange={onBAQChange}
								>
									{baq.map((item, index) => {
										return <option value={item.formId}>{item.title}</option>;
									})}
								</select>
							</div> */}
						</div>
						<div className="item-padd">
							{busy.BAQ ? (
								<Loading />
							) : (
								<div className="row">
									<div
										key={selectedBAQ.formId}
										className="col-sm-12 col-lg-9 col-xl-8"
									>
										<div className="questionnaire-info">
											<div className="row">
												<div className="col-md-4 mb-1">
													<div className="status-block">
														<h4>{selectedBAQ.totalQuestions}</h4>
														<p>Total Questions</p>
													</div>
												</div>
												<div className="col-md-4 mb-1">
													<div className="status-block blue-block">
														<h4>{selectedBAQ.answered}</h4>
														<p>Answered Questions</p>
													</div>
												</div>
												<div className="col-md-4 mb-1">
													<div className="status-block yellow-block">
														<h4>{selectedBAQ.pending}</h4>
														<p>Pending Questions</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										key={`${selectedBAQ.formId}_1`}
										className="col-sm-12 col-lg-3 col-xl-4"
									>
										<div className="progress-ring-wrap">
											<svg className="progress-ring" width={222} height={222}>
												<circle
													className="progress-ring__circle"
													style={getCircle(
														(selectedBAQ.answered /
															selectedBAQ.totalQuestions) *
															100
													)}
													stroke="#0132b7"
													strokeWidth={10}
													fill="#c4d4ff"
													r={100}
													cx={110}
													cy={110}
												/>
											</svg>
											<svg
												className="progress-ring-inactive"
												width={222}
												height={222}
											>
												<circle
													className="progress-ring__circle"
													stroke="#f4f4f4"
													strokeWidth={0}
													fill="#f4f4f4"
													r={100}
													cx={110}
													cy={110}
												/>
											</svg>
											<div className="progress-value">
												<input
													defaultValue={`${(
														(selectedBAQ.answered /
															selectedBAQ.totalQuestions) *
														100
													).toFixed(0)}%`}
													type="text"
													disabled
													placeholder="0%"
												/>
												<p>Completed</p>
											</div>
										</div>
										{/* <button
										className="btn-primary-c proceed-btn"
										onClick={() => handleClick()}
									>
										{selectedBAQ.isEnabled ? "DISABLE BAQ" : "ENABLE BAQ"}
									</button> */}
										{authState.user.userType === UserType.BIP && (
											<button
												className="btn-primary-c proceed-btn"
												disabled={!selectedBAQ.isEnabled}
												onClick={() => goToAnswering()}
											>
												{"REVIEW ANSWER"}
											</button>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="item-block">
						<div className="item-head d-flex justify-content-between">
							<h3>Intermediate Report</h3>
							<div className="d-flex">
								<div className="col-md-7">
									<div className="form-group row">
										<label className="col-form-label">Report date</label>
										<div className="col-sm-8">
											<input
												type="date"
												onChange={(e) => onDateChange(e)}
												value={reportDate}
												className="form-control"
											></input>
										</div>
									</div>
								</div>
								<div className="col">
									<select
										className="form-control"
										value={selectedTemplate}
										onChange={onTemplateChange}
									>
										<option value="">Default</option>
										{templates.map((item, index) => {
											return (
												<option value={item.fileName}>
													{item.fileName}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>
						<div className="item-padd">
							<div className="row">
								<div className="col-md-12 text-center">
									<p>
										Generate intermediate report with the report template
										selected and download it. <br /> You can always edit the
										answers if needed and regenerate the report.
									</p>
									{/* <div className="row justify-content-center">
										<div className="col-md-2 pb-2">
											<label>Calculation date</label>
											<input
												type="date"
												onChange={(e) => onDateChange(e)}
												value={reportDate}
												className="form-control"
											></input>
										</div>
									</div> */}

									<button
										className="btn btn-primary"
										disabled={disableIntermediateReport}
										onClick={onGenerateDownload}
									>
										<i className="fas fa-file-import"></i> Generate Intermediate
										Report
									</button>
									<div className="mt-2"></div>

									{state.reportLoaderIsBusy && (
										<>
											<Loading />
											<span>
												Please wait while we are generating the report.It
												may take some time.
											</span>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="item-block">
						<div className="item-head d-flex justify-content-between">
							<h3>BA Report</h3>
							<div className="col-md-4">
								<button
									className="btn btn-primary float-right"
									disabled={publishButtonDisabled}
									onClick={() => publishReport()}
								>
									<i className="fas fa-paper-plane"></i> Publish to Client
								</button>
							</div>
						</div>
						<div className="item-padd">
							{state.uploadLoaderIsBusy ? (
								<Loading />
							) : (
								<>
									{reports.map((report: IReportDetails, index: number) => {
										return (
											<div className="col-md-4">
												<div className="row">
													<div className="col-md-2">
														<img src="/pdf_icon.png" alt="icon" />
													</div>
													<div className="col-md-8">
														{report.fileName}
													</div>
													<div className="col-md-2">
														<button
															className="btn btn-sm btn-outline-dark"
															onClick={() =>
																onRemoveReport(report.reportId)
															}
														>
															<i className="fas fa-trash"></i>
														</button>
													</div>
												</div>
											</div>
										);
									})}
									<div className="text-center">
										<p>
											Upload the final Business Analysis report and publish it
											to the client.
										</p>
										<input
											name="img"
											type="file"
											accept=".pdf"
											ref={fileInputRef}
											onChange={handleFileChanged}
											style={{ display: "none" }}
										/>
										<button
											className="btn btn-primary"
											disabled={disableIntermediateReport}
											onClick={handleUploadButtonClick}
										>
											<i className="fas fa-upload"></i> Upload
										</button>
									</div>
								</>
							)}
						</div>
					</div>
				</>
			)}
			{/* </div>
			</div> */}
			{modal && (
				<FormConfig
					onCancel={toggle}
					onSubmit={onSubmit}
					config={formInfo}
					onChange={onChange}
				/>
			)}
		</>
	);
	// return (
	// 	<Stack tokens={sectionStackTokens}>
	// 		<Stack horizontal grow={12}>
	// 			<Stack.Item grow={10} align="start">
	// 				<Breadcrumb
	// 					items={itemsWithHref}
	// 					ariaLabel="Breadcrumb with items rendered as links"
	// 					overflowAriaLabel="More links"
	// 				/>
	// 			</Stack.Item>
	// 			<Stack.Item align="end">
	// 				<PrimaryButton
	// 					text={"Edit Client"}
	// 					onClick={() => {
	// 						onEdit(clientUserQuery.clientId);
	// 					}}
	// 				/>
	// 			</Stack.Item>
	// 		</Stack>
	// 		<Stack>
	// 			<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 		</Stack>
	// 		<Stack className={classes.card}>
	// 			<Persona
	// 				{...personaWithInitials}
	// 				initialsColor={PersonaInitialsColor.lightBlue}
	// 				text={`${firstName} ${lastName}`}
	// 				size={PersonaSize.size72}
	// 			/>
	// 		</Stack>
	// 		<Stack.Item className={classes.cardMain}>
	// 			<Stack.Item className={classes.cardMainHeading}>
	// 				<Stack horizontal={true}>
	// 					<Stack.Item grow={10}>
	// 						<Text variant={"xLarge"}>Questionnaire</Text>
	// 					</Stack.Item>
	// 					<Stack.Item grow={2}>
	// 						<Dropdown
	// 							selectedKey={selectedBAQ.formId}
	// 							onChange={onBAQChange}
	// 							options={loadBaq()}
	// 						/>
	// 					</Stack.Item>
	// 				</Stack>
	// 			</Stack.Item>
	// 			<Stack horizontal className={classes.cardContent}>
	// 				<Stack tokens={horizontalGapStackTokens} grow={8} horizontal>
	// 					<Stack.Item
	// 						tokens={verticalStackTokens}
	// 						className={classes.smallCard}
	// 						grow={11}
	// 					>
	// 						<Stack.Item className={classes.count}>
	// 							<Text variant={"xxLarge"}>
	// 								{selectedBAQ && selectedBAQ.totalQuestions}
	// 							</Text>
	// 						</Stack.Item>
	// 						<Stack.Item>
	// 							<Text variant={"large"}>Total Questions</Text>
	// 						</Stack.Item>
	// 					</Stack.Item>
	// 					<Stack.Item
	// 						tokens={verticalStackTokens}
	// 						className={classes.smallCard}
	// 						grow={11}
	// 					>
	// 						<Stack.Item className={classes.count}>
	// 							<Text variant={"xxLarge"}>
	// 								{selectedBAQ && selectedBAQ.answered}
	// 							</Text>
	// 						</Stack.Item>
	// 						<Stack.Item>
	// 							<Text variant={"large"}>Answered Questions</Text>
	// 						</Stack.Item>
	// 					</Stack.Item>
	// 					<Stack.Item
	// 						tokens={verticalStackTokens}
	// 						className={classes.smallCard}
	// 						grow={11}
	// 					>
	// 						<Stack.Item className={classes.count}>
	// 							<Text variant={"xxLarge"}>
	// 								{selectedBAQ && selectedBAQ.pending}
	// 							</Text>
	// 						</Stack.Item>
	// 						<Stack.Item>
	// 							<Text variant={"large"}>Pending Questions</Text>
	// 						</Stack.Item>
	// 					</Stack.Item>
	// 					<Stack.Item
	// 						tokens={verticalStackTokens}
	// 						className={classes.smallCard}
	// 						grow={11}
	// 					>
	// 						<Stack.Item className={classes.count}>
	// 							<Text variant={"xxLarge"}>
	// 								{(
	// 									(selectedBAQ.answered / selectedBAQ.totalQuestions) *
	// 									100
	// 								).toFixed()}
	// 								%
	// 							</Text>
	// 							<Stack.Item>
	// 								<Text variant={"large"}>Completed</Text>
	// 							</Stack.Item>
	// 						</Stack.Item>
	// 					</Stack.Item>
	// 				</Stack>
	// 				<Stack grow={1}>
	// 					<PrimaryButton
	// 						style={{ marginTop: "39px" }}
	// 						onClick={() => handleClick()}
	// 						text={
	// 							selectedBAQ.isEnabled
	// 								? "DISABLE QUESTIONNAIRE"
	// 								: "ENABLE QUESTIONNAIRE"
	// 						}
	// 					/>
	// 				</Stack>
	// 			</Stack>
	// 		</Stack.Item>

	// 		{/* intermediate report */}
	// 		<Stack.Item className={classes.cardMain}>
	// 			<Stack.Item className={classes.cardMainHeading}>
	// 				<Stack horizontal={true}>
	// 					<Stack.Item grow={10}>
	// 						<Text variant={"xLarge"}>Intermediate Report</Text>
	// 					</Stack.Item>
	// 					<Stack.Item grow={2} align="end">
	// 						<Dropdown
	// 							selectedKey={selectedTemplate}
	// 							onChange={onTemplateChange}
	// 							options={loadTemplates()}
	// 						/>
	// 					</Stack.Item>
	// 				</Stack>
	// 			</Stack.Item>
	// 			<Stack className={classes.cardContent}>
	// 				<Stack.Item align="center">
	// 					<p style={{ fontSize: 22, textAlign: "center" }}>
	// 						Generate intermediate report with the report template selected and
	// 						download it. <br /> You can always edit the answers if needed and
	// 						regenerate the report.
	// 					</p>
	// 				</Stack.Item>
	// 				<Stack.Item align="center">
	// 					<PrimaryButton
	// 						disabled={disableIntermediateReport}
	// 						onClick={onGenerateDownload}
	// 					>
	// 						GENERATE INTERMEDIATE REPORT
	// 					</PrimaryButton>
	// 				</Stack.Item>
	// 				<Stack.Item>
	// 					{state.reportLoaderIsBusy && (
	// 						<Spinner
	// 							label="Generating report..."
	// 							ariaLive="assertive"
	// 							labelPosition="right"
	// 						/>
	// 					)}
	// 				</Stack.Item>
	// 			</Stack>
	// 		</Stack.Item>

	// 		{/*upload report */}
	// 		<Stack.Item className={classes.cardMain}>
	// 			<Stack.Item className={classes.cardMainHeading}>
	// 				<Stack horizontal={true}>
	// 					<Stack.Item grow={10}>
	// 						<Text variant={"xLarge"}>BA Report</Text>
	// 					</Stack.Item>
	// 					<Stack.Item align="end">
	// 						<PrimaryButton
	// 							disabled={publishButtonDisabled}
	// 							text={publishButtonText}
	// 							onClick={() => publishReport()}
	// 						/>
	// 					</Stack.Item>
	// 				</Stack>
	// 			</Stack.Item>
	// 			<Stack className={classes.cardContent}>
	// 				<Stack.Item>
	// 					{reports.map((report: IReportDetails, index: number) => {
	// 						return (
	// 							<Stack horizontal>
	// 								<Stack.Item>
	// 									<img src="/pdf_icon.png" alt="icon" />
	// 								</Stack.Item>
	// 								<Stack.Item>{report.fileName}</Stack.Item>
	// 								<Stack.Item>
	// 									<IconButton
	// 										iconProps={{ iconName: "ChromeClose" }}
	// 										title="Emoji"
	// 										ariaLabel="Emoji"
	// 										onClick={() => onRemoveReport(report.reportId)}
	// 									/>
	// 								</Stack.Item>
	// 							</Stack>
	// 						);
	// 					})}
	// 				</Stack.Item>
	// 				<Stack.Item align="center">
	// 					<p style={{ fontSize: 22, textAlign: "center" }}>
	// 						Upload the final Business Analysis report and publish it to the client.
	// 					</p>
	// 				</Stack.Item>
	// 				<Stack.Item align="center">
	// 					<input
	// 						name="img"
	// 						type="file"
	// 						accept=".pdf"
	// 						ref={fileInputRef}
	// 						onChange={handleFileChanged}
	// 						style={{ display: "none" }}
	// 					/>
	// 					<PrimaryButton
	// 						type="file"
	// 						disabled={disableIntermediateReport}
	// 						onClick={handleUploadButtonClick}
	// 						text="UPLOAD"
	// 					/>
	// 				</Stack.Item>
	// 			</Stack>
	// 		</Stack.Item>
	// 	</Stack>
	// );
};
