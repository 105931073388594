import { Reducer } from "use-immer";
import { IAuthState, AuthDispatcherContext, IUserCredential, IAuthenticatedUser } from ".";
import { useContext } from "react";
import { baseUrl } from "api/config";

export type IAuthAction =
	| {
			type: "SIGN_IN";
			auth: IAuthState;
	  }
	| {
			type: "SET_EMAIL";
			emailAddress: string | undefined;
	  }
	| {
			type: "SET_PASSWORD";
			password: string | undefined;
	  }
	| {
			type: "SIGN_OUT";
	  }
	| {
			type: "SET_LOGIN_FAILED";
	  }
	| {
			type: "REQUIRED_VALIDATION";
	  }
	| {
			type: "SET_BUSY";
			isBusy: boolean;
	  };

export const authReducer: Reducer<IAuthState, IAuthAction> = (draft, action): IAuthState => {
	switch (action.type) {
		case "SET_EMAIL":
			draft.credential.emailAddress = action.emailAddress;
			return draft;
		case "SET_PASSWORD":
			draft.credential.password = action.password;
			return draft;
		case "SIGN_IN":
			if (action.auth) {
				localStorage.setItem("ProfituneAuth", "true");
				const authenticatedUser: IAuthenticatedUser = action.auth.user;
				localStorage.setItem("UserInfo", JSON.stringify(authenticatedUser));
				draft.user = authenticatedUser;
				draft.expiry = action.auth.expiry;
				draft.token = action.auth.token;
				draft.refreshToken = action.auth.refreshToken;
				draft.credential = {
					emailAddressErrorMessage: "",
					emailAddress: "",
					password: "",
					passwordErrorMessage: "",
					errorMessage: "",
				};
			} else {
				localStorage.clear();
				draft.credential.emailAddress = "";
				draft.credential.password = "";
				// draft.credential.emailAddressErrorMessage = "Error";
				// draft.credential.passwordErrorMessage = "Error";
				draft.credential.errorMessage = "Email address or password invalid!";
			}
			draft.authenticated = localStorage.getItem("ProfituneAuth");
			draft.isBusy = false;
			return draft;
		case "SET_LOGIN_FAILED":
			localStorage.clear();
			draft.credential.emailAddress = "";
			draft.credential.password = "";
			// draft.credential.emailAddressErrorMessage = "Error";
			// draft.credential.passwordErrorMessage = "Error";
			draft.credential.errorMessage = "Email address or password invalid!";
			draft.authenticated = localStorage.getItem("ProfituneAuth");
			draft.isBusy = false;
			return draft;
		case "SIGN_OUT":
			localStorage.clear();
			draft.authenticated = localStorage.getItem("ProfituneAuth");
			draft.credential = {
				emailAddressErrorMessage: "",
				emailAddress: "",
				password: "",
				passwordErrorMessage: "",
				errorMessage: "",
			};
			draft.expiry = "";
			draft.token = "";
			draft.refreshToken = "";
			draft.user = {
				emailAddress: "",
				firstName: "",
				lastName: "",
				roleId: 0,
				userId: 0,
				userType: 0,
				userRolePermissions: [],
			};
			return draft;
		case "REQUIRED_VALIDATION":
			const credential = draft.credential;
			credential.emailAddressErrorMessage = "";
			credential.passwordErrorMessage = "";
			if (!credential.emailAddress) {
				credential.emailAddressErrorMessage = "Please enter email";
			}

			if (!credential.password) {
				credential.passwordErrorMessage = "Please enter password";
			}
			return draft;
		case "SET_BUSY":
			draft.isBusy = action.isBusy;
			return draft;
		default:
			throw Error("unknown action");
	}
};

const axios = require("axios");

export const Login = (dispatcher: React.Dispatch<IAuthAction>, credential: IUserCredential) => {
	const { emailAddress, password } = credential;
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.post(baseUrl + "Auth",credential)
			.then((res: any) => {
				const auth: IAuthState = res.data;
				if (res.status === 200) {
					dispatcher({ type: "SIGN_IN", auth });
				} else {
					dispatcher({ type: "SET_LOGIN_FAILED" });
				}
			})
			.catch((err: any) => {
				dispatcher({ type: "SET_LOGIN_FAILED" });
			});
	} catch (e) {}
};

export const requiredValidation = (dispatcher: React.Dispatch<IAuthAction>) => {
	dispatcher({ type: "REQUIRED_VALIDATION" });
};

export const onChangeEmail = (
	dispatcher: React.Dispatch<IAuthAction>,
	emailAddress: string | undefined
) => {
	dispatcher({ type: "SET_EMAIL", emailAddress });
};

export const onChangePassword = (
	dispatcher: React.Dispatch<IAuthAction>,
	password: string | undefined
) => {
	dispatcher({ type: "SET_PASSWORD", password });
};

export const LogOut = (dispatcher: React.Dispatch<IAuthAction>) => {
	dispatcher({ type: "SIGN_OUT" });
};

export const UserAuthDispatcher = (): React.Dispatch<IAuthAction> => {
	const authDispatcher = useContext(AuthDispatcherContext);
	if (!authDispatcher)
		throw new Error(
			"You have to provide the app scope dispatcher using the AppScopeProvider in the master component."
		);
	return authDispatcher;
};
