import {
	ChoiceGroup,
	Dropdown,
	IBreadcrumbItem,
	IChoiceGroupOption,
	IDropdownOption,
	IStackTokens,
	PrimaryButton,
	Stack,
	TextField,
} from "@fluentui/react";
import { Input } from "client/components/Controls/Input";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ErrorDialog, Loading } from "shared/components";
import { useError } from "shared/hooks";
import { ICreateUserProps, IUserQuery, useUser } from ".";
import { useStyles } from "./CreateUser.styles";
import { AvForm } from "availity-reactstrap-validation";
import { Card, Breadcrumb, BreadcrumbItem, FormGroup, Label } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
const verticalGapStackTokens: IStackTokens = {
	childrenGap: 15,
	padding: 10,
};

const horizontalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 5,
};

export const CreateUser: React.FC<ICreateUserProps> = (props) => {
	const { mode, onUserNavigateBack } = props;
	const queryString = require("query-string");
	const location = useLocation();
	const classes = useStyles();
	const userQuery = queryString.parse(location.search) as IUserQuery;
	const { errorstate, onErrorClose, onError } = useError();
	const { state, onCreate, onSave, onChange } = useUser(mode, userQuery, onError);
	const { selectedUser, roles } = state;
	const {
		emailAddress,
		firstName,
		lastName,
		userId,
		designation,
		roleId,
		userStatus,
	} = selectedUser;

	if (state.isBusy) {
		return <Loading />;
	}

	const options: IChoiceGroupOption[] = [
		{ key: "A", text: "Active" },
		{ key: "B", text: "InActive" },
	];
	const itemsWithHref: IBreadcrumbItem[] = [
		{ text: "User", key: "f3", onClick: onUserNavigateBack },
	];

	if (mode === "CREATE") itemsWithHref.push({ text: "Create User", key: "f4" });
	if (mode === "EDIT") {
		if (userId && firstName && lastName) {
			itemsWithHref.push({ text: "Edit User", key: "f4" });
		}
	}

	const getRoles = () => {
		// let roleList: IDropdownOption[] = [];

		// roles.forEach((role) => {
		// 	roleList.push({
		// 		key: role.roleId,
		// 		text: `${role.roleName}`,
		// 	});
		// });

		// return roleList;
		return roles
			.filter((c) => c.roleId !== 2)
			.map((item) => {
				return <option value={item.roleId}>{item.roleName}</option>;
			});
	};

	const onFirstNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("FirstName", newValue);
	};

	const onLastNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("LastName", newValue);
	};

	const onEmailChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("Email", newValue);
	};

	const onDesignationChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("Designation", newValue);
	};

	const onChangeRole = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		let selected = event.target.value;
		if (selected) {
			onChange && onChange("RoleId", selected);
		}
	};

	const onChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange && onChange("UserStatus", event.target.checked ? "1" : "0");
	};

	return (
		<>
			<ErrorDialog
				errorMessage={errorstate.errorMessage}
				show={errorstate.visible}
				onClose={onErrorClose}
			/>
			<Breadcrumb tag="nav" listTag="div">
				<BreadcrumbItem tag={Link} to="/admin/home">
					Dashboard
				</BreadcrumbItem>
				<BreadcrumbItem tag={Link} to="/admin/user">
					User
				</BreadcrumbItem>
				<BreadcrumbItem active>
					{mode === "CREATE" ? "New User" : "Edit User"}
				</BreadcrumbItem>
			</Breadcrumb>
			<AvForm onValidSubmit={mode === "CREATE" ? onCreate : onSave}>
				<div className="col-md-8">
					<Card>
						<div className="card-body">
							<div className="form-wrap">
								<div className="row">
									<div className="col-md-6">
										<Input
											name="firstName"
											label="First Name"
											required="true"
											autoComplete="off"
											value={firstName}
											onChange={onFirstNameChange}
										></Input>
									</div>
									<div className="col-md-6">
										<Input
											name="lastName"
											label="Last Name"
											required="true"
											autoComplete="off"
											value={lastName}
											onChange={onLastNameChange}
										></Input>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<Input
											name="emailAddress"
											type="email"
											label="Email"
											required="true"
											autoComplete="off"
											helpMessage="Password will be send to this email."
											value={emailAddress}
											onChange={onEmailChange}
										></Input>
									</div>
									<div className="col-md-6">
										<Input
											name="designation"
											required="true"
											autoComplete="off"
											label="Designation"
											value={designation}
											onChange={onDesignationChange}
										></Input>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<Input
											name="role"
											type="select"
											value={roleId}
											label="Role"
											onChange={onChangeRole}
										>
											<option value="">Choose</option>
											{getRoles()}
										</Input>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<div className="pt-checkbox">
												<input
													id={`status`}
													type="Checkbox"
													checked={userStatus === 0 ? false : true}
													onChange={onChangeStatus}
												/>
												<label className="pt-checkbox" htmlFor={`status`}>
													Is Active
												</label>
											</div>
										</div>
									</div>
								</div>

								<button type="submit" className="btn btn-primary">
									Save
								</button>
							</div>
						</div>
					</Card>
				</div>
			</AvForm>
		</>
	);

	// return (
	// 	<Stack tokens={verticalGapStackTokens} className={classes.root}>
	// 		<Stack.Item>
	// 			<Breadcrumb
	// 				items={itemsWithHref}
	// 				maxDisplayedItems={3}
	// 				ariaLabel="Breadcrumb with items rendered as links"
	// 				overflowAriaLabel="More links"
	// 			/>
	// 		</Stack.Item>
	// 		<Stack>
	// 			<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 		</Stack>
	// 		<Stack className={classes.form}>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<TextField
	// 						label="First Name"
	// 						value={firstName}
	// 						onChange={onFirstNameChange}
	// 					/>
	// 				</Stack.Item>
	// 				<Stack.Item grow={6}>
	// 					<TextField label="Last Name" value={lastName} onChange={onLastNameChange} />
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<TextField label="Email" value={emailAddress} onChange={onEmailChange} />
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<TextField
	// 						label="Designation"
	// 						value={designation}
	// 						onChange={onDesignationChange}
	// 					/>
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 				<Stack.Item grow={6}>
	// 					<Dropdown
	// 						label="Roles"
	// 						selectedKey={roleId}
	// 						onChange={onChangeRole}
	// 						options={getRoles()}
	// 					/>
	// 				</Stack.Item>
	// 			</Stack>

	// 			<Stack.Item tokens={horizontalGapStackTokens}>
	// 				<ChoiceGroup
	// 					defaultSelectedKey="A"
	// 					options={options}
	// 					//onChange={_onChange}
	// 					label="Account Status"
	// 					required={true}
	// 				/>
	// 			</Stack.Item>
	// 			<Stack.Item>
	// 				{mode === "CREATE" ? (
	// 					<PrimaryButton text={"SAVE"} onClick={onCreate} />
	// 				) : (
	// 					<PrimaryButton text={"SAVE"} onClick={onSave} />
	// 				)}
	// 			</Stack.Item>
	// 		</Stack>
	// 	</Stack>
	// );
};
