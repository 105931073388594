import { baseUrl } from "api/config";
import { IUseErrorHook } from "shared/hooks";
import { Reducer } from "use-immer";
import { ClientFieldMode, IClient, IClientQuery, IClientState, IReportDetails } from "..";
import { IPaging } from "../containers";
import FileDownload from "js-file-download";
export type IClientAction =
	| {
			type: "REPORT_LOADER";
			reportLoaderIsBusy: boolean;
	  }
	| {
			type: "UPLOAD_REPORT_LOADER";
			uploadLoaderIsBusy: boolean;
	  }
	| {
			type: "SET_CLIENTS";
			clients: IClient[];
			paging: IPaging[];
	  }
	| {
			type: "SET_CLIENT_AND_REPORTS";
			client: IClient;
			reports: IReportDetails[];
	  }
	| {
			type: "ADD_CLIENT";
	  }
	| {
			type: "UPDATE_CLIENT";
	  }
	| {
			type: "SET_BUSY";
			isBusy: boolean;
	  }
	| {
			type: "ON_CHANGE";
			clientFieldMode: ClientFieldMode;
			newValue?: string;
	  }
	| {
			type: "SET_REPORTS";
			reports: IReportDetails[];
	  }
	| {
			type: "SUBMITTED";
	  };

export const clientReducer: Reducer<IClientState, IClientAction> = (
	draft,
	action
): IClientState => {
	switch (action.type) {
		case "UPLOAD_REPORT_LOADER":
			draft.uploadLoaderIsBusy = action.uploadLoaderIsBusy;
			return draft;
		case "REPORT_LOADER":
			draft.reportLoaderIsBusy = action.reportLoaderIsBusy;
			return draft;
		case "SET_CLIENTS":
			draft.clients = action.clients;
			draft.isBusy = false;
			draft.init = false;
			return draft;
		case "SET_CLIENT_AND_REPORTS":
			draft.init = false;
			draft.selectedClient = action.client;
			draft.reports = action.reports;
			draft.isBusy = false;
			return draft;
		case "SET_REPORTS":
			draft.reports = action.reports;
			draft.isBusy = false;
			return draft;
		case "SET_BUSY":
			draft.isBusy = action.isBusy;
			return draft;
		case "ON_CHANGE":
			switch (action.clientFieldMode) {
				case "Email":
					draft.selectedClient.emailAddress = action.newValue;
					return draft;
				case "LastName":
					draft.selectedClient.lastName = action.newValue;
					return draft;
				case "FirstName":
					draft.selectedClient.firstName = action.newValue;
					return draft;
				case "currency":
					draft.selectedClient.currency = action.newValue;
					return draft;
				case "BusinessName":
					draft.selectedClient.businessName = action.newValue;
					return draft;
				case "AddressLine1":
					draft.selectedClient.addressLine1 = action.newValue;
					return draft;
				case "AddressLine2":
					draft.selectedClient.addressLine2 = action.newValue;
					return draft;
				case "City":
					draft.selectedClient.city = action.newValue;
					return draft;
				case "Street":
					draft.selectedClient.street = action.newValue;
					return draft;
				case "Zip":
					draft.selectedClient.zip = action.newValue;
					return draft;
				case "BusinessId":
					draft.selectedClient.businessId = action.newValue;
					return draft;
				case "Mobile":
					draft.selectedClient.mobile = action.newValue;
					return draft;
				case "OfficePhone":
					draft.selectedClient.officePhone = action.newValue;
					return draft;
				case "CountryId":
					draft.selectedClient.countryId = action.newValue
						? parseInt(action.newValue?.toString())
						: null;
					return draft;
				case "BIP":
					draft.selectedClient.bipid = action.newValue
						? parseInt(action.newValue?.toString())
						: null;
					return draft;
				case "UserStatus":
					if (action.newValue)
						draft.selectedClient.userStatus = parseInt(action.newValue);
					return draft;
				default:
					throw Error("unknown action");
			}
		case "SUBMITTED":
			draft.submitted = true;
			return draft;
		default:
			throw Error("unknown action");
	}
};

const axios = require("axios");

export const getClients = (
	dispatcher: React.Dispatch<IClientAction>,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + `Client`)
			.then((res: any) => {
				const clients: IClient[] = res.data;
				dispatcher({ type: "SET_CLIENTS", clients, paging: res.headers["x-pagination"] });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const getClientsByBIP = (
	dispatcher: React.Dispatch<IClientAction>,
	bipId: number,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + `Client/GetByBIP/${bipId}`)
			.then((res: any) => {
				const clients: IClient[] = res.data;
				dispatcher({ type: "SET_CLIENTS", clients, paging: res.headers["x-pagination"] });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const getClientById = (
	dispatcher: React.Dispatch<IClientAction>,
	query: IClientQuery,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + "Client/" + query.clientId)
			.then((res: any) => {
				let client: IClient = res.data;
				let reports: IReportDetails[] = [];
				axios
					.get(baseUrl + "Report/GetReports/" + query.clientId)
					.then((res: any) => {
						reports = res.data;
						dispatcher({ type: "SET_CLIENT_AND_REPORTS", client, reports });
					})
					.catch((err: any) => {
						onError(err.response.data);
					});
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const createClient = (
	dispatcher: React.Dispatch<IClientAction>,
	client: IClient,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.post(baseUrl + "Client", client)
			.then((res: any) => {
				dispatcher({ type: "SUBMITTED" });
				dispatcher({ type: "SET_BUSY", isBusy: false });
			})
			.catch((err: any) => {
				dispatcher({ type: "SET_BUSY", isBusy: false });
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const updateClient = (
	dispatcher: React.Dispatch<IClientAction>,
	client: IClient,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.put(baseUrl + "Client/" + client.clientId, client)
			.then((res: any) => {
				dispatcher({ type: "SUBMITTED" });
				dispatcher({ type: "SET_BUSY", isBusy: false });
			})
			.catch((err: any) => {
				dispatcher({ type: "SET_BUSY", isBusy: false });
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export interface IReportDownLoad {
	formId: number;
	clientId: number;
	template: string;
	calculationDate: string;
}

export const downloadReport = (
	dispatcher: React.Dispatch<IClientAction>,
	form: number,
	client: number,
	selectedTemplate: string,
	reportDate: string,
	onError: IUseErrorHook["onError"]
) => {
	try {
		const reportDownLoad: IReportDownLoad = {
			formId: form,
			clientId: client,
			template: selectedTemplate,
			calculationDate: reportDate,
		};
		dispatcher({ type: "REPORT_LOADER", reportLoaderIsBusy: true });
		axios
			.post(baseUrl + "Report/GenerateReport", reportDownLoad, {
				responseType: "blob",
			})
			.then((res: any) => {
				let fileName = "";
				var header = res.headers["content-disposition"];
				var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				var matches = filenameRegex.exec(header);
				if (matches != null && matches[1]) {
					fileName = matches[1].replace(/['"]/g, "");
				}
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
				dispatcher({ type: "REPORT_LOADER", reportLoaderIsBusy: false });
			})
			.catch((err: any) => {
				err.response.data.text().then((text: any) => {
					onError(text ?? "An error has occured while generating the report");
				});

				dispatcher({ type: "REPORT_LOADER", reportLoaderIsBusy: false });
			});
	} catch (e) {
		onError(e.message);
	}
};

export const exportAnswers = (
	dispatcher: React.Dispatch<IClientAction>,
	form: number,
	client: number,
	selectedTemplate: string,
	reportDate: string,
	onError: IUseErrorHook["onError"]
) => {
	try {
		const reportDownLoad: IReportDownLoad = {
			formId: form,
			clientId: client,
			template: selectedTemplate,
			calculationDate: reportDate,
		};

		fetch(baseUrl + `Report/download/xlsx/${reportDownLoad.formId}/${reportDownLoad.clientId}`)
			.then((response) => response.blob())
			.then((blob) => {
				const fileName = `answer_export_baq_${reportDownLoad.formId}_client_${reportDownLoad.clientId}.xlsx`;
				FileDownload(blob, fileName);
			})
			.catch((error) => {
				console.error("Error downloading the file:", error);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const uploadReport = async (
	dispatcher: React.Dispatch<IClientAction>,
	files: File[],
	formId: number,
	clientId: number,
	onError: IUseErrorHook["onError"]
) => {
	dispatcher({ type: "UPLOAD_REPORT_LOADER", uploadLoaderIsBusy: true });
	const form = new FormData();
	form.set("FormId", formId.toString());
	form.set("ClientId", clientId.toString());
	files.forEach((s) => {
		form.append("files", s);
	});

	axios({
		method: "post",
		url: baseUrl + "Report/Upload",
		data: form,
		headers: {
			"content-type": `multipart/form-data;`,
		},
	})
		.then((res: any) => {
			getReports(dispatcher, clientId, onError);
		})
		.catch((err: any) => {
			onError(err.response.data);
		});
};

export const getReports = (
	dispatcher: React.Dispatch<IClientAction>,
	client: number,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + "Report/GetReports/" + client)
			.then((res: any) => {
				const reports: IReportDetails[] = res.data;
				dispatcher({ type: "SET_REPORTS", reports });
				dispatcher({ type: "UPLOAD_REPORT_LOADER", uploadLoaderIsBusy: false });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const removeReport = (
	dispatcher: React.Dispatch<IClientAction>,
	reportId: number,
	client: number,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.delete(baseUrl + "Report/" + reportId)
			.then((res: any) => {
				getReports(dispatcher, client, onError);
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const removeClient = (
	dispatcher: React.Dispatch<IClientAction>,
	clientId: number,
	onError: IUseErrorHook["onError"]
) => {
	dispatcher({ type: "SET_BUSY", isBusy: true });
	try {
		axios
			.delete(baseUrl + "Client/" + clientId)
			.then((res: any) => {
				getClients(dispatcher, onError);
			})
			.catch((err: any) => {
				onError(err.response.data);
				dispatcher({ type: "SET_BUSY", isBusy: false });
			});
	} catch (e) {
		onError(e.message);
		dispatcher({ type: "SET_BUSY", isBusy: false });
	}
};
